import { CorrespondenceTagRead, MailIMAP } from '@app/generated'

/**
 * Converts a base64 encoded string to a unicode string.
 *
 * @param {string} str - The base64 encoded string to convert.
 * @returns {string} - The converted unicode string.
 */
export const base64ToUnicode = (str: string): string => {
	const binaryString: string = atob(str)

	const bytes: Uint8Array = new Uint8Array(binaryString.length)
	for (let i: number = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}

	const decoder: TextDecoder = new TextDecoder('utf-8')
	return decoder.decode(bytes)
}

/**
 * Replaces all URLs in a string with HTML anchor tags.
 *
 * @param {string} body - The input string to be processed.
 * @returns {object} - An object containing the processed content and a boolean indicating if any replacements were made.
 */
export const transformLinksToHTML = (body: string): { content: string; replaced: boolean } => {
	const result: string = body.replace(/(https?:\/\/[^\s]+)/g, (url: string) => {
		return `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`
	})
	return { content: result, replaced: result.length !== body.length }
}

export const printMail = (mail: MailIMAP, content: string) => {
	const printWindow = window.open('', '', 'height=600,width=800')
	if (!printWindow) {
		return
	}
	const documentContent = `
    <html>
      <head>
        <title>Signa Mail Print</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            color: #333;
          }
          .email-header {
            margin-bottom: 20px;
          }
          .email-header div {
            margin-bottom: 5px;
          }
          .email-body {
            white-space: pre-wrap;
            line-height: 1.5;
          }
          .email-footer {
            margin-top: 20px;
            font-size: 0.9em;
            color: #666;
          }
        </style>
      </head>
      <body>
        <div class="email-header">
          <div><strong>From:</strong> ${mail.sender}</div>
          <div><strong>To:</strong> ${mail.to?.map(to => to.email)}</div>
          <div><strong>Subject:</strong> ${mail.subject}</div>
          <div><strong>Date:</strong> ${mail.received_at}</div>
        </div>
        <div class="email-body">
          ${content}
        </div>
        <div class="email-footer">
          ${$localize`Email was printed from Signa application`}
        </div>
      </body>
    </html>
  `

	printWindow.document.open()
	printWindow.document.write(documentContent)
	printWindow.document.close()

	printWindow.focus()
	printWindow.print()
}

/**
 * Retrieves the translated category text for a correspondence tag in a specific language.
 *
 * @param {CorrespondenceTagRead} tag - The correspondence tag object.
 * @param {string} language - The language code for the desired translation.
 * @returns {string} The translated category text. Returns an empty string if no translation is found.
 */
export const getCorrespondenceTagCategoryTranslation = (tag: CorrespondenceTagRead, language: string): string => {
	let categoryText = tag.category_text && (tag.category_text as { [key: string]: string })[language]

	if (!categoryText && tag.category_text) {
		const firstKey = Object.keys(tag.category_text)[0]
		categoryText = (tag.category_text as { [key: string]: string })[firstKey]
	}

	return categoryText || ''
}
