import { Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { CorrespondenceTagRead, CorrespondenceTagShort } from '@app/generated'
import { getItemTranslation } from '@app/general/utils/get-item-translation'
import { LanguageService } from '@core/services/language.service'
import { getCorrespondenceTagCategoryTranslation } from '@app/mailbox/functions/mailbox.utils'
import { ModalService } from '@core/services/modal.service'
import { NotificationService } from '@core/services/notification.service'
import { MailboxInnerService } from '@app/mailbox/services/mailbox-inner.service'

interface TagItem {
	tag?: CorrespondenceTagRead
	dividerText?: string
}

@Component({
	selector: 'app-tag-mail',
	templateUrl: './tag-mail.component.html',
	styleUrl: './tag-mail.component.scss',
})
export class TagMailComponent {
	tagControl = new FormControl<CorrespondenceTagRead[] | null>([])

	tags: TagItem[] = []
	alreadyAssignedTags: CorrespondenceTagShort[] = []
	protected readonly textSave = $localize`Save`
	protected readonly textTagLabel = $localize`Select tag`

	constructor(
		private languageService: LanguageService,
		private modalService: ModalService,
		private notificationService: NotificationService,
		private mailboxInnerService: MailboxInnerService,
	) {}

	ngOnInit(): void {
		this.fetchData()
	}

	// TODO DISABLE PAGINATION WITEK
	fetchData(): void {
		this.mailboxInnerService.fetchCorrespondenceTags().subscribe({
			next: response => {
				this.tagControl.patchValue(
					response.items.filter(item => {
						return this.alreadyAssignedTags.map(assigned => assigned.id).includes(item.id)
					}),
				)
				const groupedTags: { [key: string]: CorrespondenceTagRead[] } = {}

				for (const tag of response.items) {
					const category: string = this.getTagCategoryTranslation(tag)
					if (groupedTags[category]) {
						groupedTags[category].push(tag)
					} else {
						groupedTags[category] = [tag]
					}
				}
				for (const tag in groupedTags) {
					const tags = groupedTags[tag].map(t => ({ tag: t }))
					this.tags.push({ dividerText: tag })
					this.tags = this.tags.concat(tags)
				}
			},
			error: () => {
				console.error('Failed to fetch tags')
			},
		})
	}

	submit(): void {
		const instance = this.modalService.modalRef?.componentInstance
		if (!instance || !(instance instanceof TagMailComponent)) {
			console.error('Create a handler for this use scenario')
			return
		}

		if (this.tagControl.invalid) {
			this.notificationService.warning($localize`Please select directory first`)
			return
		}

		this.modalService.modalRef.close(this.tagControl.value)
	}

	/**
	 * Retrieves the translation of the category of a given tag.
	 *
	 * @param {CorrespondenceTagRead} tag - The tag whose category translation needs to be retrieved.
	 * @return {string} The translation of the tag category.
	 */
	private getTagCategoryTranslation(tag: CorrespondenceTagRead): string {
		return getCorrespondenceTagCategoryTranslation(
			tag,
			this.languageService.getSelectedLanguage?.substring(0, 2) || 'en',
		)
	}

	/**
	 * Checks if an option is selected.
	 *
	 * @param {TagItem} tag - The tag item to check.
	 * @returns {boolean} - Returns true if the option is selected, otherwise false.
	 */
	isOptionSelected(tag: TagItem): boolean {
		if (tag.tag) {
			return !!this.tagControl.value?.includes(tag.tag)
		}
		return false
	}

	protected readonly getItemTranslation = getItemTranslation
}
