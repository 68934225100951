<epic-form-field [text]="textTagLabel">
	<epic-select
		[control]="tagControl"
		[multiSelect]="true"
		[customSelectedValues]="true">
		@if (!!tagControl.value?.length) {
			<div
				class="epic-select__custom-values"
				selected-values>
				@for (tag of tagControl.value; track tag.id) {
					<span class="epic-select__value-round">{{ getItemTranslation(tag) }}</span>
				}
			</div>
		}
		@for (tag of tags; track tag) {
			<epic-option
				[isDivider]="!!tag.dividerText"
				[selected]="isOptionSelected(tag)"
				[value]="tag.tag">
				@if (tag.tag) {
					<div class="option">
						<div
							class="option__cube"
							[style.background-color]="tag.tag.background_color"></div>
						<span>{{ getItemTranslation(tag.tag) }}</span>
					</div>
				} @else {
					{{ tag.dividerText }}
				}
			</epic-option>
		}
	</epic-select>
</epic-form-field>
<div class="submit-row">
	<epic-button
		[text]="textSave"
		(onClick)="submit()" />
</div>
