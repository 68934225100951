import { Component } from '@angular/core'
import { IMAPDirectories } from '@app/generated'
import { FormControl, Validators } from '@angular/forms'
import { ModalService } from '@core/services/modal.service'
import { NotificationService } from '@core/services/notification.service'

@Component({
	selector: 'app-move-mail',
	templateUrl: './move-mail.component.html',
	styleUrl: './move-mail.component.scss',
})
export class MoveMailComponent {
	dictionaryControl: FormControl<IMAPDirectories | null> = new FormControl(null, Validators.required)
	private directoriesList: { text: string; key: IMAPDirectories }[] = [
		{
			text: $localize`Inbox`,
			key: 'INBOX',
		},
		{ text: $localize`Spam`, key: 'Junk' },
		{ text: $localize`Outbox`, key: 'Sent' },
		{ text: $localize`Trash`, key: 'Trash' },
	]
	disabledKeys: IMAPDirectories[] = []

	protected readonly textMoveLabel = $localize`Select directory`
	protected readonly textSave = $localize`Save`

	constructor(
		private modalService: ModalService,
		private notificationService: NotificationService,
	) {}

	/**
	 * Returns an array of directories.
	 *
	 * @return {Array.<{text: string, key: IMAPDirectories}>} The array of directories.
	 */
	get directories(): { text: string; key: IMAPDirectories }[] {
		return this.directoriesList.filter(directory => !this.disabledKeys.find(k => k === directory.key))
	}

	/**
	 * Submits the form and closes the modal dialog.
	 *
	 * If the modal dialog component instance is not found, an error is logged to the console.
	 * If the dictionary control is invalid, a warning notification is shown.
	 * Finally, the modal dialog is closed with the value of the dictionary control.
	 *
	 * @returns {void}
	 */
	submit(): void {
		const instance = this.modalService.modalRef?.componentInstance
		if (!instance) {
			console.error('Create a handler for this use scenario')
			return
		}

		if (this.dictionaryControl.invalid) {
			this.notificationService.warning($localize`Please select directory first`)
			return
		}

		this.modalService.modalRef.close(this.dictionaryControl.value)
	}
}
