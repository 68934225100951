<epic-form-field [text]="textLabel">
	<epic-autocomplete
		[control]="ordersControl"
		(searchChange)="fetchOrders($event)"
		[multiSelect]="true"
		[customSelectedValues]="true"
		[forceDuplicationDetection]="true">
		@if (ordersControl.value) {
			<div
				class="epic-select__custom-values"
				selected-values>
				@for (order of getSelectedValue; track $index) {
					<span class="epic-select__value-round">{{ order }}</span>
				}
			</div>
		}
		@for (order of orders; track $index) {
			<epic-option
				[selected]="isSelected(order)"
				[value]="order"
				><span>{{ order.number }} </span>
				@if (!order?.conveyance_type?.is_container_number_required) {
					@if (order?.package_count && order?.container_weight) {
						({{ order?.package_count }}<span i18n>PC</span> / {{ order?.container_weight }}<span i18n>kg</span>)
					} @else if (order?.package_count) {
						({{ order?.package_count }}<span i18n>PC</span>)
					} @else if (order?.container_weight) {
						({{ order?.container_weight }}<span i18n>kg</span>)
					} @else {
						({{ order?.container_number }})
					}
				} @else {
					{{ order?.container_number }}
				}
			</epic-option>
		}
	</epic-autocomplete>
</epic-form-field>
<div class="submit-row">
	<epic-button
		[text]="textSave"
		(onClick)="submit()" />
</div>
