<div class="mails">
	@if (selectedMail && emailPreviewStyle === 'fullscreen') {
		@if (isInsideModal) {
			<epic-button
				size="small"
				(click)="closePreview(null)">
				<span class="row align__center">
					<epic-icon
						name="arrow-left"
						class="margin__right--small" />
					<span i18n>Back</span>
				</span>
			</epic-button>
		}
		<app-email-preview
			[mail]="selectedMail"
			[isInsideModal]="isInsideModal"
			[orderId]="selectedOrderId"
			(onClose)="closePreview($event)" />
	} @else {
		@if (selectedMail && emailPreviewStyle === 'side') {
			<app-email-preview
				#emailPreviewSide
				class="email-preview--side"
				[mail]="selectedMail"
				[isInsideModal]="isInsideModal"
				[orderId]="selectedOrderId"
				[isSideStyle]="true"
				(onClose)="closePreview($event)" />
		}
		<app-mailbox-tabs
			[currentTab]="pageType"
			[mailboxTabs]="mailboxTabs"
			[mailboxAdditionalTabs]="mailboxAdditionalTabs"
			[emitOnlyEventOnChange]="isInsideModal"
			(tabChange)="tabChange.emit($event)" />
		<div class="filters-container">
			@if (filtersVisible) {
				<div [ngTemplateOutlet]="filtersTemplate"></div>
			}
		</div>
		<epic-card
			[isCollapseAllowed]="false"
			class="margin__top">
			<div
				start
				class="row align__center top-actions">
				<h2 class="margin__right">
					{{ pageName }}
				</h2>
				<epic-icon-button
					(click)="refresh()"
					[text]="text.refresh"
					hoverColor="var(--color__black)"
					iconHoverColor="var(--color__white)"
					iconHoverQuerySelector="path"
					iconName="refresh" />
				<div class="top-actions__filters">
					<div class="top-actions__filters-search row">
						<epic-form-field>
							<epic-input
								(keydown.enter)="onSearchEnter()"
								[placeholder]="text.searchPlaceholder"
								[control]="filters.controls.search" />
						</epic-form-field>
						<div class="top-actions__filters-checkboxes">
							<label
								class="font__small font-weight__semibold"
								i18n
								>Search in:
							</label>
							@for (searchType of searchTypes; track $index) {
								<div
									class="top-actions__filters-checkbox clickable font__tiny"
									(click)="onSearchTypeChange(searchType); $event.preventDefault(); $event.stopImmediatePropagation()">
									<epic-checkbox [control]="searchType.control" />
									<span>{{ searchType.label }}</span>
								</div>
							}
						</div>
					</div>
					@if (!isInsideModal) {
						@if (this.filtersVisible) {
							<epic-icon-button
								(click)="resetFilters()"
								[text]="text.clearFilters"
								hoverColor="var(--color__danger)"
								iconHoverColor="var(--color__white)"
								iconHoverQuerySelector="path"
								iconName="broom" />
						} @else {
							<epic-icon-button
								(click)="showFilters()"
								[text]="text.filters"
								hoverColor="var(--color__black)"
								iconHoverColor="var(--color__white)"
								iconHoverQuerySelector="path"
								iconName="filter-add" />
						}
					}
				</div>
			</div>
			<epic-form-field
				class="top-actions__center"
				center>
				<epic-autocomplete
					[control]="selectedAccountControl"
					(modelChange)="onEmailAccountChange($event)"
					(searchChange)="fetchEmailAddresses($event)"
					[placeholder]="text.selectEmailPlaceholder"
					[customSelectedValues]="true">
					<div
						class="epic-select__custom-values"
						selected-values>
						{{ selectedAccountControl.value?.username }}
					</div>
					@for (email of emailAccounts; track $index) {
						<epic-option [value]="email">{{ email.username }}</epic-option>
					}
				</epic-autocomplete>
			</epic-form-field>
			@if (!isInsideModal) {
				<div
					class="top-actions__right top-actions"
					end>
					@if (aclIsAdmin()) {
						<div #options>
							<epic-icon-button
								[text]="text.analyzeAll"
								hoverColor="var(--color__black)"
								iconHoverColor="var(--color__white)"
								iconHoverQuerySelector="path"
								iconName="analyze-mail-all" />
						</div>
						@if (options) {
							<epic-popover
								#optionspopover
								class="font__small"
								[parentReference]="options">
								<div
									popover-header
									i18n>
									Analyze options
								</div>
								<div
									class="epic-popover__list"
									popover-content>
									<div
										class="epic-popover__item"
										(click)="analyzeWithErrors(); optionspopover.closePopover()"
										i18n>
										Analyze emails with errors
									</div>
									<div
										(click)="analyzeWithNoOrders(); optionspopover.closePopover()"
										class="epic-popover__item"
										i18n>
										Analyze emails with no orders
									</div>
								</div>
							</epic-popover>
						}
					}
					<epic-icon-button
						(click)="moveMails()"
						[text]="text.move"
						hoverColor="var(--color__black)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="move" />
					@if (aclCanOrderRead) {
						<epic-icon-button
							(click)="assOrderBulkActions()"
							[text]="text.assignOrder"
							hoverColor="var(--color__black)"
							iconHoverColor="var(--color__white)"
							iconHoverQuerySelector="path"
							iconName="kanban" />
					}
					<epic-icon-button
						(click)="deleteMail()"
						[text]="text.delete"
						hoverColor="var(--color__danger)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="delete" />
				</div>
			}
			<epic-table
				#tableComponent
				(queryChange)="queryChanged($event)"
				[generateCheckboxes]="!isInsideModal"
				(checkboxesChange)="checkboxesChanged($event)"
				class="font__small">
				<tr
					class="font-weight__bold"
					table-headers
					table-row-data
					[is-header-checkbox]="true">
					<epic-table-header
						class="font-align__center table-column__xs"
						[epic-tooltip]="text.markedWithAStar">
						<epic-icon name="star" />
					</epic-table-header>
					<epic-table-header
						class="font-align__center table-column__xs"
						[epic-tooltip]="text.withAnAttachment">
						<epic-icon name="document-attachment-muted" />
					</epic-table-header>
					<epic-table-header
						class="font-align__center table-column__xs"
						[epic-tooltip]="text.unread">
						<epic-icon name="mark_email_unread" />
					</epic-table-header>
					<epic-table-header
						class="table-column__md"
						sortable
						dataKey="from"
						><span i18n>Sender</span></epic-table-header
					>
					<epic-table-header
						class="subject-cell"
						sortable
						dataKey="subject">
						<span i18n>Subject</span>
					</epic-table-header>
					<epic-table-header class="table-column__sm">
						<span i18n>Order number</span>
					</epic-table-header>
					<epic-table-header
						sortable
						dataKey="date"
						class="table-column__sm">
						<span i18n>Date</span>
					</epic-table-header>
					@if (!isInsideModal) {
						<td></td>
					}
				</tr>
				@for (model of models; track $index) {
					<tr
						[class.focus--border]="getLocalStorageScroll?.scrollTargetId === model.id"
						[table-row-data]="model"
						[class.selected-row]="selectedMail?.id === model.id">
						<td
							(click)="starMessage(model)"
							class="font-align__center table-column__xs star-icon c-pointer"
							[class.star-icon--flagged]="model.flagged"
							[epic-tooltip]="text.markWithAStar">
							<epic-icon name="star" />
						</td>
						@if (model.has_attachments) {
							<td
								class="font-align__center table-column__xs row align__center justify__center clickable inbox-attachments"
								(click)="downloadAllAttachments($index)"
								[epic-tooltip]="text.hasAttachments">
								<span class="document-attachment-icon">
									<epic-icon name="document-attachment-muted" />
								</span>
							</td>
						} @else {
							<td></td>
						}
						<td
							(click)="readMessageAction(model)"
							class="font-align__center table-column__xs row align__center justify__center c-pointer"
							[epic-tooltip]="model.seen ? text.read : text.unread">
							@if (model.seen) {
								<epic-icon name="dot" />
							} @else {
								<epic-icon name="dot-danger" />
							}
						</td>
						<td class="table-column__md table-column__lg--max">
							<span [class.unread]="!model.seen">
								@if (model.sender_name) {
									<span [epic-tooltip]="model.sender ?? undefined"> {{ model.sender_name }} </span>
								} @else {
									{{ model.sender }}
								}
							</span>
						</td>
						<td>
							<span
								class="clickable"
								[class.unread]="!model.seen"
								(click)="selectMail(model)">
								<div class="mail-space-between">
									<div>{{ model.subject?.length ? model.subject : text.emailNoSubject }}</div>
									@if (model.is_forwarded) {
										<epic-icon
											[epic-tooltip]="text.forwardedMessage"
											class="mail-color"
											name="forward" />
									}
								</div>
							</span>
							<div class="subject-cell__tags">
								@for (tag of getMailTags(model); track $index) {
									<div
										class="subject-cell__tag tag"
										[style.background-color]="tag.background_color"
										[style.color]="tag.text_color">
										{{ getItemTranslation(tag) }}
									</div>
								}
							</div>
						</td>
						<td>
							@for (order of model.orders; track $index) {
								<span
									class="clickable"
									(click)="goToOrder(order)"
									[class.unread]="!model.seen">
									@if (order.container_number) {
										{{ order.container_number }}
									} @else {
										{{ order.order_number }}
									}
								</span>
							}
						</td>
						<td class="font-align__center table-column__sm">
							<span [class.unread]="!model.seen">{{ model.received_at | date: 'dd.MM.yyyy HH:mm' }}</span>
						</td>
						@if (!isInsideModal) {
							<td class="actions-cell">
								<div class="font-align__center row">
									<epic-icon-button
										(click)="forward(model)"
										[text]="text.forward"
										hoverColor="var(--color__black)"
										iconHoverColor="var(--color__white)"
										iconHoverQuerySelector="path"
										iconName="forward" />
									<epic-icon-button
										(click)="assignTag(model)"
										[text]="text.tags"
										hoverColor="var(--color__black)"
										iconHoverColor="var(--color__white)"
										iconHoverQuerySelector="path"
										iconName="tags" />
									<epic-icon-button
										(click)="deleteMail(model)"
										[text]="text.delete"
										hoverColor="var(--color__danger)"
										iconHoverColor="var(--color__white)"
										iconHoverQuerySelector="path"
										iconName="delete" />
								</div>
							</td>
							<td
								class="c-pointer actions-cell"
								#actions>
								<epic-icon-button [iconName]="'more'" />
								@if (actions) {
									<epic-popover
										#popoverComp
										class="font__small"
										[parentReference]="actions">
										<div
											popover-header
											i18n>
											{{ model.subject }}
										</div>
										<div
											class="epic-popover__list"
											popover-content>
											@if (aclCanOrderRead) {
												<div
													class="epic-popover__item"
													(click)="addOrder(model)"
													i18n>
													Add Order
												</div>
											}
											<div
												(click)="print(model)"
												class="epic-popover__item"
												i18n>
												Print
											</div>
											<div
												(click)="moveMails(model)"
												class="epic-popover__item"
												i18n>
												Move
											</div>
											<div
												(click)="analyzeMail(model); popoverComp.closePopover()"
												class="epic-popover__item"
												i18n>
												Analyze mail
											</div>
										</div>
									</epic-popover>
								}
							</td>
						}
					</tr>
				}
			</epic-table>
			<epic-pagination [pagination]="pagination" />
		</epic-card>
	}
</div>

<ng-template #filtersTemplate>
	<div
		class="filters form"
		(clickOutside)="closeFilters()">
		<epic-form-field
			class="form__element-3"
			[text]="text.filterTags">
			<epic-select
				[control]="filters.controls.tags"
				[multiSelect]="true"
				[customSelectedValues]="true"
				(modelChange)="onTagFilterChange()">
				@if (!!filters.controls.tags.value?.length) {
					<div
						class="epic-select__custom-values"
						selected-values>
						@for (tag of filters.controls.tags.value; track $index) {
							<span class="epic-select__value-round">{{
								tag === false ? text.notAssigned : getItemTranslation(tag)
							}}</span>
						}
					</div>
				}
				<epic-option
					[value]="false"
					[selected]="false">
					{{ text.notAssigned }}
				</epic-option>
				@for (tag of tagsList; track $index) {
					<epic-option
						[isDivider]="!!tag.dividerText"
						[selected]="isTagOptionSelected(tag)"
						[isDisabled]="!isMultiSelectTagsEnabled() && !tag.dividerText"
						[value]="tag.tag">
						@if (tag.tag) {
							<div class="option">
								<div
									class="option__cube"
									[style.background-color]="tag.tag.background_color"></div>
								<span>{{ getItemTranslation(tag.tag) }}</span>
							</div>
						} @else {
							{{ tag.dividerText }}
						}
					</epic-option>
				}
			</epic-select>
		</epic-form-field>
		@if (aclCanOrderRead) {
			<epic-form-field
				class="form__element-3"
				[text]="text.filtersOrders">
				<epic-autocomplete
					[control]="filters.controls.orders"
					[customSelectedValues]="true"
					(searchChange)="fetchOrders($event)"
					[multiSelect]="true"
					[forceDuplicationDetection]="true"
					(modelChange)="onOrderFilterChange()">
					<div
						class="epic-select__custom-values"
						selected-values>
						@for (order of filters.controls.orders.value; track $index) {
							<span class="epic-select__value-round">{{ order === false ? text.notAssigned : order.number }}</span>
						}
					</div>
					<epic-option
						[value]="false"
						[selected]="false">
						{{ text.notAssigned }}
					</epic-option>
					@for (order of ordersList; track order.id) {
						<epic-option
							[isDisabled]="!isMultiSelectOrderEnabled()"
							[selected]="isOrderFilterSelected(order)"
							[value]="order"
							>{{ order.number }}
							@if (order.container_number) {
								({{ order.container_number }})
							}
						</epic-option>
					}
				</epic-autocomplete>
			</epic-form-field>
		}
		<!--		datyy-->
		<epic-form-field-group
			class="form__element-3"
			[text]="text.filtersSendMessageLabel"
			datePicker>
			<epic-input
				[focused]="!!filters.controls.dateFrom.value"
				[control]="filters.controls.dateFrom"
				[placeholder]="text.from" />
			<epic-input
				[focused]="!!filters.controls.dateTo.value"
				[control]="filters.controls.dateTo"
				[placeholder]="text.to" />
		</epic-form-field-group>
		<!--		sender-->
		<epic-form-field
			[text]="text.sender"
			class="form__element-3">
			<epic-autocomplete
				(searchChange)="handleSenderValueChanges($event)"
				[(searchValue)]="senderSearchValue"
				[isLoading]="isSenderSearchLoading"
				[customSelectedValues]="true"
				[multiSelect]="true"
				[control]="filters.controls.sender">
				<div
					class="epic-select__custom-values"
					selected-values>
					@for (sender of filters.controls.sender.value; track $index) {
						<span class="epic-select__value-round">
							@if (sender['name']) {
								{{ sender['name'] }}
							} @else {
								{{ sender['email'] }}
							}
						</span>
					}
				</div>
				@if (senderSuggestion) {
					<epic-option [value]="senderSuggestion">
						<div class="row align__center">
							<span>{{ senderSuggestion?.email }}</span>
						</div>
					</epic-option>
				}
				@for (contact of senderContactsList; track contact.id) {
					<epic-option [value]="contact">
						<span> {{ contact.name }} </span>
						<span class="margin__left--small text-color__muted"> <{{ contact.email }}> </span>
					</epic-option>
				}
			</epic-autocomplete>
		</epic-form-field>
		<!--		opdbiorca-->
		<epic-form-field
			[text]="text.receiver"
			class="form__element-3">
			<epic-autocomplete
				(searchChange)="handleReceiverValueChanges($event)"
				[(searchValue)]="receiverSearchValue"
				[isLoading]="isReceiverSearchLoading"
				[customSelectedValues]="true"
				[multiSelect]="true"
				[control]="filters.controls.receiver"
				(afterClosing)="handleFocusOutReceiver()">
				<div
					class="epic-select__custom-values"
					selected-values>
					@for (receiver of filters.controls.receiver.value; track $index) {
						<span class="epic-select__value-round">
							@if (receiver['name']) {
								{{ receiver['name'] }}
							} @else {
								{{ receiver['email'] }}
							}
						</span>
					}
				</div>
				@if (receiverSuggestion) {
					<epic-option [value]="receiverSuggestion">
						<div class="row align__center">
							<span>{{ receiverSuggestion?.email }}</span>
						</div>
					</epic-option>
				}
				@for (contact of receiverContactsList; track contact.id) {
					<epic-option [value]="contact">
						<span> {{ contact.name }} </span>
						<span class="margin__left--small text-color__muted"> <{{ contact.email }}> </span>
					</epic-option>
				}
			</epic-autocomplete>
		</epic-form-field>
		<epic-form-field
			class="form__element-3"
			[text]="text.filterSeen">
			<!--			seen -->
			<epic-select [control]="filters.controls.seen">
				@for (value of seenFilterList; track $index) {
					<epic-option
						(click)="$event.stopPropagation()"
						[value]="value.value">
						{{ value.text }}
					</epic-option>
				}
			</epic-select>
		</epic-form-field>
		<epic-form-field
			class="form__element-3"
			[text]="text.filterFlagged">
			<!--			flagged -->
			<epic-select [control]="filters.controls.flagged">
				@for (value of flaggedFilterList; track $index) {
					<epic-option
						(click)="$event.stopPropagation()"
						[value]="value.value">
						{{ value.text }}
					</epic-option>
				}
			</epic-select>
		</epic-form-field>
		<epic-form-field
			class="form__element-3"
			[text]="text.filterAttachment">
			<!--			attachment -->
			<epic-select [control]="filters.controls.withAttachment">
				@for (value of attachmentFilterList; track $index) {
					<epic-option
						(click)="$event.stopPropagation()"
						[value]="value.value">
						{{ value.text }}
					</epic-option>
				}
			</epic-select>
		</epic-form-field>

		<div class="filters__submit form__element-12">
			<epic-button
				(click)="filterClick()"
				size="large">
				<epic-icon name="show-all" />
				<span i18n>Filter</span>
			</epic-button>
		</div>
	</div>
</ng-template>
