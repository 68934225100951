<div class="new-mail__container">
	<div class="new-mail__content">
		<div class="new-mail__header">
			<h2 i18n>New email</h2>
			<div class="row align__center">
				<span
					class="font__small margin__right no-wrap-text"
					i18n
					>From</span
				>
				<epic-autocomplete
					class="new-email__select new-mail__header-select--from"
					[control]="formGroup.controls.from"
					(modelChange)="onSelectedEmailChange()"
					[customSelectedValues]="true">
					@if (formGroup.controls.from.value?.username) {
					<div
						class="epic-select__custom-values"
						selected-values>
						{{ formGroup.controls.from.value?.username }}
					</div>
					} @for (account of emailAccounts; track account.id) {
					<epic-option
						[value]="account"
						[selected]="account.id === formGroup.controls.from.value?.id">
						{{ account.username }}
					</epic-option>
					}
				</epic-autocomplete>
			</div>
			<div class="new-mail__header-select new-mail__header-select--order row align__center">
				<span
					class="font__small margin__right no-wrap-text"
					i18n
					>Order numbers</span
				>
				<epic-autocomplete
					[(searchValue)]="orderNumberInput"
					(searchValueChange)="fetchOrders()"
					[control]="formGroup.controls.orders"
					[multiSelect]="true"
					[customSelectedValues]="true"
					[forceDuplicationDetection]="true"
					class="new-email__select">
					<div
						class="epic-select__custom-values"
						selected-values>
						<span
							*ngFor="let order of formGroup.controls.orders.value"
							class="epic-select__value-round"
							>{{ order.number }}</span
						>
					</div>
					@for (order of orders; track $index) {
					<epic-option
						*ngIf="order.number"
						[selected]="isOrderSelected(order.id)"
						[value]="order">
						{{ order.number }} {{ order.container_number ? '(' + order.container_number + ')' : '' }}
					</epic-option>
					}
				</epic-autocomplete>
			</div>
			<div class="new-mail__header-select new-mail__header-select--tags row align__center">
				<span
					class="font__small margin__right no-wrap-text"
					i18n
					>Tags</span
				>
				<epic-select
					[control]="formGroup.controls.tags"
					[multiSelect]="true"
					[customSelectedValues]="true">
					@if (!!formGroup.controls.tags.value?.length) {
					<div
						class="epic-select__custom-values"
						selected-values>
						@for (tag of formGroup.controls.tags.value; track $index) {
						<span class="epic-select__value-round">{{ getItemTranslation(tag) }}</span>
						}
					</div>
					} @for (tag of tagsList; track $index) {
					<epic-option
						[isDivider]="!!tag.dividerText"
						[selected]="isTagOptionSelected(tag)"
						[value]="tag.tag">
						@if (tag.tag) {
						<div class="option">
							<div
								class="option__cube"
								[style.background-color]="tag.tag.background_color"></div>
							<span>{{ getItemTranslation(tag.tag) }}</span>
						</div>
						} @else { {{ tag.dividerText }} }
					</epic-option>
					}
				</epic-select>
			</div>
			<div class="row align__center">
				@if (draftIsSaved) {
				<epic-icon name="spinner" />
				} @else {
				<epic-icon-button
					(click)="saveDraft()"
					[text]="text.saveDraft"
					hoverColor="var(--color__black)"
					iconHoverColor="var(--color__white)"
					iconHoverQuerySelector="path"
					iconName="submit-save" />
				@if (draftID) {
				<epic-icon-button
					(click)="deleteDraft()"
					[text]="text.deleteDraft"
					hoverColor="var(--color__danger)"
					iconHoverColor="var(--color__white)"
					iconHoverQuerySelector="path"
					iconName="delete" />
				} }
			</div>
		</div>
		<div class="new-mail__body">
			<div class="new-mail__item">
				<epic-form-field [text]="text.to">
					<epic-autocomplete
						(searchChange)="handleToValueChanges($event)"
						[(searchValue)]="toSearchValue"
						[isLoading]="isToSearchLoading"
						[customSelectedValues]="true"
						[multiSelect]="true"
						[control]="formGroup.controls.to"
						(afterClosing)="handleFocusOutTo()">
						<div
							class="epic-select__custom-values"
							selected-values>
							@for (to of formGroup.controls.to.value; track $index) {
							<span class="epic-select__value-round">
								@if (to['name']) { {{ to['name'] }} } @else { {{ to['email'] }} }
								<button
									class="clickable"
									(click)="dropTo($index); $event.stopPropagation()">
									<epic-icon name="close" />
								</button>
							</span>
							}
						</div>
						@if (toSuggestion) {
						<epic-option [value]="toSuggestion">
							<div class="row align__center">
								<span>{{ toSuggestion?.email }}</span>
							</div>
						</epic-option>
						} @for (contact of toContacts; track contact.id) {
						<epic-option [value]="contact">
							<span> {{ contact.name }} </span>
							<span class="margin__left--small text-color__muted"> <{{ contact.email }}> </span>
						</epic-option>
						}
					</epic-autocomplete>
				</epic-form-field>
				<div class="new-mail__actions">
					@if (!ccVisible) {
					<epic-icon-button
						type="button"
						(click)="addCcClick()"
						[text]="text.cc"
						hoverColor="var(--color__black)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="copy" />
					} @if (!bccVisible) {
					<epic-icon-button
						type="button"
						(click)="addBccClick()"
						[text]="text.bcc"
						hoverColor="var(--color__black)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="hidden-copy" />
					}
				</div>
			</div>
			@if (ccVisible) {
			<div class="new-mail__item">
				<epic-form-field [text]="text.cc">
					<epic-autocomplete
						(searchChange)="handleCcValueChanges($event)"
						[(searchValue)]="ccSearchValue"
						[isLoading]="isCcSearchLoading"
						[customSelectedValues]="true"
						[multiSelect]="true"
						[control]="formGroup.controls.cc"
						(afterClosing)="handleFocusOutCc()">
						<div
							class="epic-select__custom-values"
							selected-values>
							@for (cc of formGroup.controls.cc.value; track $index) {
							<span class="epic-select__value-round">
								@if (cc['name']) { {{ cc['name'] }} } @else { {{ cc['email'] }} }
								<button
									class="clickable"
									(click)="dropCc($index); $event.stopPropagation()">
									<epic-icon name="close" />
								</button>
							</span>
							}
						</div>
						@if (ccSuggestion) {
						<epic-option [value]="ccSuggestion">
							<div class="row align__center">
								<span>{{ ccSuggestion?.email }}</span>
							</div>
						</epic-option>
						} @for (contact of ccContacts; track contact.id) {
						<epic-option [value]="contact">
							<span> {{ contact.name }} </span>
							<span class="margin__left--small text-color__muted"> <{{ contact.email }}> </span>
						</epic-option>
						}
					</epic-autocomplete>
				</epic-form-field>
				<div class="new-mail__actions">
					<epic-icon-button
						(click)="removeCcSelect()"
						[text]="text.removeCc"
						hoverColor="var(--color__danger)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="rect"
						iconName="cancel" />
				</div>
			</div>

			} @if (bccVisible) {
			<div class="new-mail__item">
				<epic-form-field [text]="text.bcc">
					<epic-autocomplete
						(searchChange)="handleBccValueChanges($event)"
						[(searchValue)]="bccSearchValue"
						[isLoading]="isBccSearchLoading"
						[customSelectedValues]="true"
						[multiSelect]="true"
						[control]="formGroup.controls.bcc"
						(afterClosing)="handleFocusOutBcc()">
						<div
							class="epic-select__custom-values"
							selected-values>
							@for (cc of formGroup.controls.bcc.value; track $index) {
							<span class="epic-select__value-round">
								@if (cc['name']) { {{ cc['name'] }} } @else { {{ cc['email'] }} }
								<button
									class="clickable"
									(click)="dropBcc($index); $event.stopPropagation()">
									<epic-icon name="close" />
								</button>
							</span>
							}
						</div>
						@if (bccSuggestion) {
						<epic-option [value]="bccSuggestion">
							<div class="row align__center">
								<span>{{ bccSuggestion?.email }}</span>
							</div>
						</epic-option>
						} @for (contact of bccContacts; track contact.id) {
						<epic-option [value]="contact">
							<span> {{ contact.name }} </span>
							<span class="margin__left--small text-color__muted"> <{{ contact.email }}> </span>
						</epic-option>
						}
					</epic-autocomplete>
				</epic-form-field>
				<div class="new-mail__actions">
					<epic-icon-button
						(click)="removeBccSelect()"
						[text]="text.removeBcc"
						hoverColor="var(--color__danger)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="rect"
						iconName="cancel" />
				</div>
			</div>
			}
			<epic-form-field [text]="text.subject">
				<epic-input
					[placeholder]="text.subject"
					[control]="formGroup.controls.subject" />
			</epic-form-field>
		</div>
		<form class="form">
			<div class="form__element-12">
				<quill-editor [formControl]="formGroup.controls.content" />
			</div>
			<epic-file-picker
				class="form__element-6"
				(filesChanged)="sendFile($event)"
				[acceptedMimeTypes]="[
				  'application/pdf',
				  'image/jpeg',
				  'image/tiff',
				  'image/png',
				  'image/webp',
				  'application/xml',
				  'text/xml',
				  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				  'application/vnd.ms-excel',
				  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				  'application/msword'
				]">
				<div class="file-picker__standard">
					<epic-icon name="add" />
					<span
						class="margin__left--small"
						i18n>
						Add file
					</span>
				</div>
			</epic-file-picker>
			<div class="form__element-6 row align__center font__small">
				<span
					[class.text-color__muted]="totalSize() == 0"
					[class.text-color__success]="totalSize() > 0 && totalSize() <= maxAttachmentsSize"
					[class.text-color__danger]="totalSize() > 0 && totalSize() > maxAttachmentsSize">
					{{ totalSize() | number : '0.2' }}
				</span>
				<span class="margin__left--small margin__right--small"> / </span>
				<span> {{ maxAttachmentsSize | number : '0.2' }} MB </span>
			</div>
			@for (file of listOfAttachments; track $index) {
			<div class="form__element-6 font__small">
				<div class="row align__center">
					<app-attachment-icon [mimeType]="file.type" />
					<span class="margin__left--small"> {{ file.fileName }} </span>
				</div>
			</div>
			<div class="form__element-6">
				<div class="row align__center">
					<div
						class="icon c-pointer"
						(click)="removeAttachment(file)">
						<epic-icon name="remove" />
					</div>
				</div>
			</div>
			} @if (aclCanInvoicesRead()) {
			<epic-form-field
				[text]="text.invoices"
				class="form__element-5">
				<epic-autocomplete
					[(searchValue)]="orderNumberInput"
					(searchValueChange)="fetchInvoices()"
					[control]="formGroup.controls.invoices"
					[multiSelect]="true"
					[customSelectedValues]="true"
					[forceDuplicationDetection]="true">
					<div
						class="epic-select__custom-values"
						selected-values>
						<span
							*ngFor="let invoice of formGroup.controls.invoices.value"
							class="epic-select__value-round"
							>{{ invoice.document_number }}</span
						>
					</div>
					@for (invoice of invoices; track $index) {
					<epic-option
						*ngIf="invoice.document_number"
						[selected]="isInvoiceSelected(invoice.id)"
						[value]="invoice">
						{{ invoice.document_number }} - {{ invoice.issue_date | date: 'dd.MM.YYYY' }}
					</epic-option>
					}
				</epic-autocomplete>
			</epic-form-field>
			<epic-form-field
				class="form__element-2"
				[text]="text.language">
				<epic-select
					[control]="formGroup.controls.language"
					[customSelectedValues]="true">
					<div
						class="epic-select__custom-values"
						selected-values>
						<span>
							<div class="epic-language-switcher-element__text">
								{{ getLanguageText(formGroup.controls.language.value) | titlecase }}
							</div>
						</span>
					</div>
					@for (languageCode of appConfig.invoiceLanguages; track languageCode) {
					<epic-option
						[selected]="languageCode == formGroup.controls.language.value"
						[value]="languageCode">
						<epic-language-switcher-element [languageCode]="languageCode" />
					</epic-option>
					}
				</epic-select>
			</epic-form-field>
			<div class="form__element-2 font__medium invoices-container-flex">
				<epic-checkbox [control]="showFromOrders" />
				<span
					(click)="showFromOrders.setValue(!showFromOrders.value); fetchInvoices()"
					class="clickable"
					i18n
					>Show invoices from selected orders</span
				>
			</div>
			}
		</form>
	</div>

	<div class="new-mail__footer">
		<epic-button
			(onClick)="submit()"
			[isConfirm]="true"
			size="large">
			<div class="row align__center justify__space-between">
				<epic-icon name="send" />
				<span
					class="margin__left"
					i18n>
					Send an email
				</span>
			</div>
		</epic-button>
	</div>
</div>
