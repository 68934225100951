import { Component, OnInit } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { AppService } from '@app/general/services/app.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
	ContactRead,
	ContactReadList,
	CorrespondenceTagRead,
	IMAPDirectories,
	InvoiceReadWithCashReceipts,
	InvoicesService,
	MailContent,
	MailDraftCreate,
	MailDraftRead,
	MailDraftUpdate,
	MailIMAP,
	MailIMAPAccountRead,
	MailService,
	OrderRead,
	OrderReadList,
	OrderReadListItem,
	OrdersService,
} from '@app/generated'
import { finalize, forkJoin, Observable, of } from 'rxjs'
import { calculateDivision } from '@app/invoices/utils/calculate-division'
import { ActivatedRoute, Router } from '@angular/router'
import { NullableBoolean, NullableString } from '@core/types/nullable'
import { MailboxInnerService } from '@app/mailbox/services/mailbox-inner.service'
import { base64ToUnicode, getCorrespondenceTagCategoryTranslation } from '@app/mailbox/functions/mailbox.utils'
import { NotificationService } from '@core/services/notification.service'
import { QuillModules } from 'ngx-quill'
import Quill, { Parchment } from 'quill'
import { LanguageService } from '@core/services/language.service'
import { ConfirmationComponent } from '@core/components/confirmation/confirmation.component'
import { ModalService } from '@core/services/modal.service'
import { Permission } from '@app/acl/enums/permissions.enum'
import { SignaComponent } from '@app/general/classes/signa-component'
import * as ISOLanguages from '@core/i18n/i18n-iso-languages'
import Embed from 'quill/blots/embed'

export interface MailAttachment {
	fileName: string
	content: string
	type: string
	size: number
}

export enum MailActionType {
	Reply = 'reply',
	ReplayAll = 'replay-all',
	Forward = 'forward',
}

interface TagItem {
	tag?: CorrespondenceTagRead
	dividerText?: string
}

class Break extends Embed {
	static override blotName = 'break'
	static override tagName = 'br'

	static override create() {
		return super.create()
	}
}

@Component({
	selector: 'app-new-email',
	templateUrl: './new-email.page.html',
	styleUrl: './new-email.page.scss',
})
export class NewEmailPage extends SignaComponent implements OnInit {
	formGroup = new FormGroup({
		content: new FormControl<NullableString>(''),
		subject: new FormControl<NullableString>(null, [Validators.required]),
		from: new FormControl<MailIMAPAccountRead | null>(null, [Validators.required]),
		to: new FormControl<any[]>([], [Validators.required]),
		cc: new FormControl<any[]>([]),
		bcc: new FormControl<any[]>([]),
		orders: new FormControl<OrderRead[] | null>(null),
		tags: new FormControl<CorrespondenceTagRead[] | null>(null),
		invoices: new FormControl<InvoiceReadWithCashReceipts[] | null>(null),
		language: new FormControl<string | null>('de'),
	})
	toSearchValue = ''
	isToSearchLoading = false
	toContacts: ContactRead[] = []
	toSuggestion?: { email: string } = undefined
	ccSearchValue = ''
	bccSearchValue = ''
	isCcSearchLoading = false
	isBccSearchLoading = false
	ccContacts: ContactRead[] = []
	bccContacts: ContactRead[] = []
	ccSuggestion?: { email: string }
	bccSuggestion?: { email: string }
	readonly text = {
		to: $localize`To`,
		cc: $localize`Cc`,
		bcc: $localize`Bcc`,
		subject: $localize`Subject`,
		saveDraft: $localize`Save Draft`,
		removeCc: $localize`Remove cc`,
		removeBcc: $localize`Remove bcc`,
		deleteDraft: $localize`Delete draft`,
		invoices: $localize`Invoices`,
		language: $localize`Language of invoice`,
	}
	listOfAttachments: MailAttachment[] = []
	maxAttachmentsSize: number = 25
	draftIsSaved: boolean = false
	orderNumberInput: string = ''
	invoiceInput: string = ''
	orders: OrderReadListItem[] = []
	emailAccounts: MailIMAPAccountRead[] = []
	modules: QuillModules = {
		clipboard: {
			matchVisual: true,
		},
	}
	ccVisible = false
	bccVisible = false

	tagsList: TagItem[] = []
	draftID?: string
	orderID?: string
	invoices: InvoiceReadWithCashReceipts[] = []

	showFromOrders: FormControl<NullableBoolean> = new FormControl<NullableBoolean>(true)

	private toValueChanges?: any
	private ccValueChanges?: any
	private bccValueChanges?: any
	private afterChangesTimeout?: any
	private mailUID?: string
	private mailDbId?: string

	private mailDirectory?: IMAPDirectories
	private mailActionType?: MailActionType

	constructor(
		private loaderService: LoaderService,
		private appService: AppService,
		private mailService: MailService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private ordersService: OrdersService,
		private mailboxInnerService: MailboxInnerService,
		private notificationService: NotificationService,
		private languageService: LanguageService,
		private modalService: ModalService,
		private invoicesService: InvoicesService,
	) {
		super()
		const BlockquoteClass = new Parchment.Attributor('class', 'blockquote', {
			scope: Parchment.Scope.BLOCK,
		})
		Quill.register(BlockquoteClass, true)
		Quill.register(Break)
		if (this.activatedRoute.snapshot.queryParams['orderId']) {
			this.orderID = this.activatedRoute.snapshot.queryParams['orderId']
		}
		this.appService.title = $localize`New Email`
		this.mailboxInnerService.fetchSelectedAccount().subscribe(this.init)
		this.mailboxInnerService.fetchCorrespondenceTags().subscribe({
			next: response => {
				const groupedTags: { [key: string]: CorrespondenceTagRead[] } = {}

				for (const tag of response.items) {
					const category: string = this.getTagCategoryTranslation(tag)
					if (groupedTags[category]) {
						groupedTags[category].push(tag)
					} else {
						groupedTags[category] = [tag]
					}
				}
				for (const tag in groupedTags) {
					const tags = groupedTags[tag].map(t => ({ tag: t }))
					this.tagsList.push({ dividerText: tag })
					this.tagsList = this.tagsList.concat(tags)
				}
			},
			error: () => {
				console.error('Failed to fetch tags')
			},
		})
		this.mailboxInnerService.selectedEmailAccountChange$.subscribe(() => {
			if (this.mailboxInnerService.selectedEmailAccount?.send_from_mail_account) {
				this.formGroup.controls.from.setValue(
					this.mailboxInnerService.selectedEmailAccount?.send_from_mail_account ?? null,
					{ emitEvent: false },
				)
				this.loaderService.disable()
			} else {
				this.formGroup.controls.from.setValue(this.mailboxInnerService.selectedEmailAccount ?? null, {
					emitEvent: false,
				})
				this.loaderService.disable()
			}
		})
		this.fetchOrders()
		if (this.aclCanInvoicesRead()) {
			this.fetchInvoices()
		}
	}

	private init = (): void => {
		if (this.activatedRoute.snapshot.queryParams['draft']) {
			this.draftID = this.activatedRoute.snapshot.queryParams['draft']
		}
		if (this.activatedRoute.snapshot.queryParams['directory']) {
			this.mailDirectory = this.activatedRoute.snapshot.queryParams['directory']
		}

		if (this.activatedRoute.snapshot.queryParams['return_to']) {
			if (this.activatedRoute.snapshot.queryParams['return_to'] == 'drafts') {
				this.appService.showButton({
					text: $localize`Drafts`,
					link: '/mailbox/drafts',
				})
			}
		} else {
			this.appService.showButton({
				text: $localize`Inbox`,
				link: '/mailbox/inbox',
			})
		}
		if (this.routeType) {
			if (this.routeType === MailActionType.Reply) {
				if (this.activatedRoute.snapshot.params['id']) {
					this.mailActionType = MailActionType.Reply
					this.mailUID = this.activatedRoute.snapshot.params['id']
				}
			} else if (this.routeType === MailActionType.Forward) {
				this.mailActionType = MailActionType.Forward
				this.mailUID = this.activatedRoute.snapshot.params['id']
				this.mailDbId = this.activatedRoute.snapshot.queryParams['dbid']
			} else if (this.routeType === MailActionType.ReplayAll) {
				this.mailActionType = MailActionType.ReplayAll
				this.mailUID = this.activatedRoute.snapshot.params['id']
			}
		}
		if (this.mailboxInnerService.selectedEmailAccount?.send_from_mail_account) {
			this.formGroup.controls.from.setValue(
				this.mailboxInnerService.selectedEmailAccount?.send_from_mail_account ?? null,
			)
			this.loaderService.disable()
		} else {
			this.formGroup.controls.from.setValue(this.mailboxInnerService.selectedEmailAccount ?? null)
			this.loaderService.disable()
		}
		this.fetchData()
	}

	get routeType(): string | MailActionType | undefined {
		return this.activatedRoute.snapshot.data['type']
	}

	ngOnInit(): void {
		if (this.mailboxInnerService.selectedEmailAccount?.mail_footer) {
			const content = base64ToUnicode(
				this.mailboxInnerService.selectedEmailAccount?.mail_footer
					? this.mailboxInnerService.selectedEmailAccount?.mail_footer
					: '',
			)
			const html = `
  <br><hr>
  <footer>${content.replace(/\n/g, '<br>')}</footer>`
			this.formGroup.controls.content.setValue(html)
		}
		this.formGroup.controls.subject.valueChanges.subscribe(this.afterChanges)
		this.formGroup.controls.content.valueChanges.subscribe(this.afterChanges)
		this.formGroup.controls.orders.valueChanges.subscribe(() => {
			this.fetchInvoices()
		})
	}

	async saveDraft(): Promise<void> {
		if (this.afterChangesTimeout) {
			clearTimeout(this.afterChangesTimeout)
		}
		if (!this.draftIsSaved) {
			const value = this.formGroup.value
			this.draftIsSaved = true
			if (!this.formGroup.controls.from.value?.id) {
				this.notificationService.error($localize`Application error`)
				return
			}
			if (!this.draftID) {
				const request: MailDraftCreate = {
					to: value.to as any,
					cc: value.cc,
					bcc: undefined,
					subject: value.subject,
					content: value.content,
					attachments: this.listOfAttachments,
					orders: (this.formGroup.controls.orders.value || []).map(order => ({ id: order.id })) || undefined,
					tags: this.formGroup.controls.tags.value || undefined,
					mail_user_imap_id: this.formGroup.controls.from.value.id,
				}
				this.mailService
					.createMailDraft(request)
					.pipe(
						finalize(() => {
							this.draftIsSaved = false
						}),
					)
					.subscribe({
						next: response => {
							this.draftID = response.id
							this.router.navigate([], {
								queryParams: { draft: response.id },
								queryParamsHandling: 'merge',
							})
						},
						error: err => {
							console.log(err)
						},
					})
			} else {
				const request: MailDraftUpdate = {
					to: value.to as any,
					cc: value.cc,
					bcc: undefined,
					subject: value.subject,
					content: value.content,
					attachments: this.listOfAttachments,
					orders: (this.formGroup.controls.orders.value || []).map(order => ({ id: order.id })) || undefined,
					tags: this.formGroup.controls.tags.value || undefined,
				}
				this.mailService
					.updateMailDraft(this.draftID, request)
					.pipe(
						finalize(() => {
							this.draftIsSaved = false
						}),
					)
					.subscribe({
						next: response => {
							this.draftID = response.id
							this.router.navigate([], {
								queryParams: { draft: response.id },
								queryParamsHandling: 'merge',
							})
						},
						error: err => {
							console.log(err)
						},
					})
			}
		}
	}

	afterChanges = (): void => {
		if (this.afterChangesTimeout) {
			clearTimeout(this.afterChangesTimeout)
		}

		this.afterChangesTimeout = setTimeout(async () => {
			await this.saveDraft()
		}, 2000)
	}

	async submit(): Promise<void> {
		if (this.formGroup.valid && this.formGroup.controls.to.value?.length) {
			const getBase64 = (value: string) => {
				const parts = value.split(',')
				if (parts.length > 1) {
					return parts[1]
				} else {
					return value
				}
			}

			if (!this.mailboxInnerService.selectedEmailAccount?.id) {
				this.notificationService.error($localize`Application error`)
				return
			}

			this.loaderService.enable(() => {
				const value = this.formGroup.value
				const body = `${value.content}`
				const validLanguages = ['en', 'pl', 'de'] as const
				const selectedLanguage = this.formGroup.controls.language.value

				const language = validLanguages.includes(selectedLanguage as any)
					? (selectedLanguage as 'en' | 'pl' | 'de')
					: 'de'
				console.log(this.mailDbId)
				this.mailService
					.sendEmail(this.mailboxInnerService.selectedEmailAccount!.id, language ?? 'en', {
						subject: value.subject ?? undefined,
						to: value.to?.map(elem => (elem as any)['email'] ?? undefined),
						cc: value.cc?.map(elem => elem['email'] ?? undefined),
						bcc: value.bcc?.map(elem => elem['email'] ?? undefined),
						body: body ?? undefined,
						attachments: this.listOfAttachments.map(elem => ({
							mime_type: elem.type,
							name: elem.fileName,
							b64_data: getBase64(elem.content),
						})),
						draft_id: this.draftID,
						order_ids: this.formGroup.controls.orders.value?.map(o => o.id) || [],
						tag_ids: (this.formGroup.controls.tags.value || []).map(v => v.id || ''),
						forward_target:
							this.routeType === MailActionType.Forward && this.mailUID && this.mailDirectory
								? {
										uid: this.mailUID,
										mailbox: this.mailDirectory,
										db_id: this.mailDbId ?? '',
									}
								: undefined,
						invoice_ids: this.formGroup.controls.invoices.value?.map(o => o.id) || [],
					})
					.subscribe({
						next: () => {
							this.router.navigateByUrl('/mailbox/inbox')
						},
						error: err => {
							console.error(err)
							this.loaderService.disable()
						},
					})
			})
		}
	}

	validateEmail(email: string): boolean {
		const emailValidator = Validators.email
		return email.length ? !emailValidator({ value: email } as any) : false
	}

	async handleToValueChanges(value: string) {
		if (this.toValueChanges) {
			clearTimeout(this.toValueChanges)
		}
		this.toValueChanges = setTimeout(() => {
			if (this.validateEmail(value)) {
				this.toSuggestion = { email: value }
			} else {
				this.toSuggestion = undefined
			}

			this.isToSearchLoading = true
			this.mailService
				.getContacts(0, 10, value)
				.pipe(finalize(() => (this.isToSearchLoading = false)))
				.subscribe({
					next: (response: ContactReadList) => {
						this.toContacts = response.items
					},
					error: error => {
						console.error(error)
					},
				})
		}, 0)
	}

	handleFocusOutTo(): void {
		setTimeout(async () => {
			this.toSuggestion = undefined
			this.toSearchValue = ''
			this.toContacts = []
		}, 250)
	}

	dropTo(index: number): void {
		const value = this.formGroup.controls.to.value
		value?.splice(index, 1)
		this.formGroup.controls.to.patchValue(value?.length ? value : null)
		this.toSearchValue = ''
		this.toContacts = []
		this.saveDraft().then()
	}

	async handleCcValueChanges(value: string) {
		if (this.ccValueChanges) {
			clearTimeout(this.ccValueChanges)
		}

		this.ccValueChanges = setTimeout(() => {
			if (this.validateEmail(value)) {
				this.ccSuggestion = { email: value }
				return
			} else {
				this.ccSuggestion = undefined
			}

			this.isCcSearchLoading = true
			this.mailService
				.getContacts(0, 10, value)
				.pipe(finalize(() => (this.isCcSearchLoading = false)))
				.subscribe({
					next: (response: ContactReadList) => {
						this.ccContacts = response.items
					},
					error: error => {
						console.error(error)
					},
				})
		}, 0)
	}

	async handleBccValueChanges(value: string) {
		if (this.bccValueChanges) {
			clearTimeout(this.bccValueChanges)
		}

		this.bccValueChanges = setTimeout(() => {
			if (this.validateEmail(value)) {
				this.bccSuggestion = { email: value }
				return
			} else {
				this.bccSuggestion = undefined
			}

			this.isBccSearchLoading = true
			this.mailService
				.getContacts(0, 10, value)
				.pipe(finalize(() => (this.isBccSearchLoading = false)))
				.subscribe({
					next: (response: ContactReadList) => {
						this.bccContacts = response.items
					},
					error: error => {
						console.error(error)
					},
				})
		}, 0)
	}

	handleFocusOutCc(): void {
		setTimeout(async () => {
			this.ccSuggestion = undefined
			this.ccSearchValue = ''
			this.ccContacts = []
		}, 250)
	}

	handleFocusOutBcc(): void {
		setTimeout(async () => {
			this.bccSuggestion = undefined
			this.bccSearchValue = ''
			this.bccContacts = []
		}, 250)
	}

	dropCc(index: number): void {
		const value = this.formGroup.controls.cc.value
		value?.splice(index, 1)
		this.formGroup.controls.cc.patchValue(value?.length ? value : null)
		this.ccSearchValue = ''
		this.ccContacts = []
		this.saveDraft().then()
	}

	dropBcc(index: number): void {
		const value = this.formGroup.controls.bcc.value
		value?.splice(index, 1)
		this.formGroup.controls.bcc.patchValue(value?.length ? value : null)
		this.bccSearchValue = ''
		this.bccContacts = []
		this.saveDraft().then()
	}

	async sendFile(files: File | FileList): Promise<void> {
		const list = files instanceof File ? [files] : files
		for (const file of Array.from(list)) {
			const reader = new FileReader()

			reader.onloadend = () => {
				const fileContent = reader.result

				if (typeof fileContent === 'string') {
					this.listOfAttachments.push({ fileName: file.name, content: fileContent, type: file.type, size: file.size })
				}
			}

			reader.readAsDataURL(file)
		}
		this.saveDraft().then()
	}

	totalSize(): number {
		const list: number[] = this.listOfAttachments.map(elem =>
			calculateDivision(calculateDivision(elem.size, 1024), 1024, 2),
		)
		return list.reduce((x, y) => x + y, 0)
	}

	fetchOrders(): void {
		this.ordersService.getOrders(0, 10, this.orderNumberInput, false, 'desc', 'created_at').subscribe({
			next: (response: OrderReadList) => {
				this.orders = response.items
			},
			error: error => {
				console.error(error)
			},
		})
	}

	fetchInvoices(): void {
		this.invoicesService
			.getInvoices(
				0,
				10,
				this.invoiceInput,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				this.showFromOrders.value
					? this.formGroup.controls.orders.value && this.formGroup.controls.orders.value.length > 0
						? this.formGroup.controls.orders.value.map(value => value.id)
						: undefined
					: undefined,
			)
			.subscribe({
				next: response => {
					this.invoices = response.items
				},
				error: () => {
					this.notificationService.error($localize`Failed to fetch invoices`)
				},
			})
	}

	private getBodyContent(htmlString: string): string {
		return base64ToUnicode(htmlString)
	}

	private fetchData(): void {
		this.fetchEmailAddresses()
		forkJoin({
			draft: this.fetchDraft(),
			mail: this.fetchMail(),
			body: this.fetchMailBody(),
		})
			.pipe(
				finalize(() => {
					this.loaderService.disable()
				}),
			)
			.subscribe({
				next: response => {
					if (response.draft) {
						this.formGroup.patchValue(response.draft as any, { emitEvent: false })
						if (response.draft.attachments) {
							this.listOfAttachments = response.draft.attachments as MailAttachment[]
						}
						if (response.draft.orders?.length) {
							forkJoin(
								response.draft.orders
									?.filter((o): o is { id: string } => 'id' in o && typeof o.id === 'string')
									.map(o => this.ordersService.getOrder(o.id)),
							).subscribe((response: OrderRead[]) => {
								this.formGroup.controls.orders.patchValue(response)
							})
						}
					} else {
						this.draftID = undefined
					}

					if (response.body || response.mail) {
						let header = ''

						if (response.mail) {
							if (this.mailActionType === MailActionType.Reply || this.mailActionType === MailActionType.ReplayAll) {
								this.formGroup.controls.subject.setValue(`Re: ${response.mail.subject}`)

								if (response.mail.sender) {
									const toEmails = response.mail.replay_to?.length
										? [response.mail.replay_to.map(r => ({ email: r.email }))[0]]
										: [{ email: response.mail.sender }]
									this.formGroup.controls.to.setValue(
										toEmails.filter(m => m.email !== this.mailboxInnerService.selectedEmailAccount!.id),
									)
								}

								if (response.mail.orders) {
									setTimeout(() => {
										if (this.orderID) {
											this.ordersService.getOrder(this.orderID).subscribe({
												next: response => {
													this.formGroup.controls.orders.patchValue([response])
												},
											})
										} else {
											if (response!.mail!.orders?.length) {
												forkJoin(response!.mail!.orders.map(o => this.ordersService.getOrder(o.id))).subscribe(
													(response: OrderRead[]) => {
														this.formGroup.controls.orders.patchValue(response)
													},
												)
											}
										}
									}, 1000)
								}

								if (this.mailActionType === MailActionType.ReplayAll) {
									if (response.mail.to?.length || response.mail.replay_to?.length || response.mail.sender) {
										const toEmails = [
											...(response.mail.replay_to?.length
												? response.mail.replay_to.map(mail => ({ email: mail.email }))
												: response.mail.to?.map(mail => ({ email: mail.email })) || []),
											{ email: response.mail.sender },
										]
											.filter((mail, index, self) => self.findIndex(m => m.email === mail.email) === index)
											.filter(m => m.email !== this.mailboxInnerService.selectedEmailAccount!.id)
										this.formGroup.controls.to.setValue(toEmails)
									}
									if (response.mail.cc?.length) {
										this.ccVisible = true
										this.formGroup.controls.cc.setValue(response.mail.cc.map(mail => ({ email: mail.email })))
									}
								}

								header = `
								<p>
									<p>--------------- Original Message ---------------</p>
									<p>From: [${response.mail.sender}]</p>
									<p>Sent: ${response.mail.received_at}</p>
									<p>Subject: ${response.mail.subject}</p>
								</p>
	              <br />
							`
							} else if (this.mailActionType === MailActionType.Forward) {
								this.formGroup.controls.subject.setValue(`FWD: ${response.mail.subject}`)

								if (response.mail.orders) {
									setTimeout(() => {
										if (this.orderID) {
											this.ordersService.getOrder(this.orderID).subscribe({
												next: response => {
													this.formGroup.controls.orders.patchValue([response])
												},
											})
										} else {
											if (response?.mail?.orders?.length) {
												forkJoin(response?.mail.orders.map(o => this.ordersService.getOrder(o.id))).subscribe(
													(response: OrderRead[]) => {
														this.formGroup.controls.orders.patchValue(response)
													},
												)
											}
										}
									}, 1000)
								}

								header = `
							<p>
								<p>--------------- Original Message ---------------</p>
								<p>From: [${response.mail.sender}]</p>
								<p>Sent: ${response.mail.received_at}</p>
								<p>Subject: ${response.mail.subject}</p>
							</p>
 							<br />
							`
							}
						}

						if (response.body) {
							let body: string
							if (this.mailActionType) {
								body = `
							 <blockquote>${this.getBodyContent(response.body.body).replace(/\n/g, '</br>')}</blockquote>`
							} else {
								body = this.getBodyContent(response.body.body).replace(/\n/g, '</br>')
							}
							const html = `
 							<br />
							<div style="color: rgb(100, 100, 100); padding: 20px !important;">
								${header}
								${body}
							</div>
								`
							if (this.mailActionType === MailActionType.Forward) {
								this.formGroup.controls.content.setValue(html)
							} else if (
								this.mailActionType === MailActionType.ReplayAll ||
								this.mailActionType === MailActionType.Reply
							) {
								this.formGroup.controls.content.setValue(html + '<br>' + this.formGroup.controls.content.value)
							}

							//
							// if (this.routeType === MailActionType.Forward) {
							// 	// 	TODO przypisac attachmenty
							// 	// jesli jest forward trzeba przekaac uid
							//
							//
							// }
						}
					}
				},
			})
	}

	private fetchDraft(): Observable<MailDraftRead | undefined> {
		if (this.draftID) {
			return this.mailService.getDraft(this.draftID)
		} else {
			return of(undefined)
		}
	}

	private fetchMail(): Observable<MailIMAP | undefined> {
		if (!this.mailUID || !this.mailboxInnerService.selectedEmailAccount?.id || !this.mailDirectory) {
			return of(undefined)
		}

		return this.mailService.getMailUid(
			this.mailUID,
			this.mailDirectory,
			this.mailboxInnerService.selectedEmailAccount.id,
		)
	}

	private fetchMailBody(): Observable<MailContent | undefined> {
		if (!this.mailUID || !this.mailboxInnerService.selectedEmailAccount?.id || !this.mailDirectory) {
			return of(undefined)
		}

		return this.mailService.getEmailBodyAndAttachments(
			this.mailUID,
			this.mailboxInnerService.selectedEmailAccount.id,
			this.mailDirectory,
			false,
			true,
		)
	}

	ngOnDestroy(): void {
		if (this.afterChangesTimeout) {
			clearTimeout(this.afterChangesTimeout)
		}
	}

	fetchEmailAddresses(search?: string): void {
		this.mailService
			.getImapAccounts(undefined, 0, 10, search, false, 'desc', false, undefined, undefined, undefined, undefined, true)
			.subscribe({
				next: response => {
					this.emailAccounts = response.items.filter(value => !value.send_from_mail_account)
				},
				error: () => {
					this.notificationService.error($localize`Failed to fetch email addresses`)
				},
			})
	}

	addCcClick(): void {
		this.ccVisible = true
	}

	addBccClick(): void {
		this.bccVisible = true
	}

	removeCcSelect(): void {
		this.ccVisible = false
		this.formGroup.controls.cc.patchValue(null)
	}

	removeBccSelect(): void {
		this.bccVisible = false
		this.formGroup.controls.bcc.patchValue(null)
	}

	isTagOptionSelected(tag: TagItem): boolean {
		if (tag.tag) {
			return !!this.formGroup.controls.tags.value?.includes(tag.tag)
		}
		return false
	}

	private getTagCategoryTranslation(tag: CorrespondenceTagRead): string {
		return getCorrespondenceTagCategoryTranslation(
			tag,
			this.languageService.getSelectedLanguage?.substring(0, 2) || 'en',
		)
	}

	deleteDraft(): void {
		if (!this.draftID) {
			return
		}
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Danger`
			modal.componentInstance.message = $localize`Are you sure to remove this draft?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (!confirmed) {
				return
			}

			this.mailService.deleteMailDraft(this.draftID!).subscribe({
				next: () => {
					window.history.back()
				},
				error: () => {
					this.notificationService.error($localize`Failed to delete draft`)
				},
			})
		})
	}

	isOrderSelected(orderId: string): boolean {
		return !!this.formGroup.controls.orders.value?.find(o => o.id === orderId)
	}

	onSelectedEmailChange(): void {
		const newMail = this.formGroup.controls.from.value
		if (!newMail) {
			return
		}
		this.mailboxInnerService.setSelectedAccount(newMail)
		this.mailboxInnerService.selectedEmailAccountChange$.next()
	}

	removeAttachment(file: MailAttachment): void {
		this.listOfAttachments = this.listOfAttachments.filter(attachment => attachment.fileName !== file.fileName)
		this.saveDraft().then()
	}

	aclCanInvoicesRead(): boolean {
		return this.aclService.hasPermission([Permission.INVOICES_READ])
	}

	isInvoiceSelected(invoiceId: string): boolean {
		return !!this.formGroup.controls.invoices.value?.find(i => i.id === invoiceId)
	}

	getIconSource(lang: string | null): string {
		if (lang) {
			const code = this.getLangCode(lang)
			return `url('icons/flags/language/${code}.svg')`
		}
		return ''
	}

	private getLangCode(lang: string): string {
		return lang.split('-')[0].toLowerCase()
	}

	getLanguageText(code: string | null): string | undefined {
		if (code) {
			const storedLanguage: string = this.languageService.getLocalStorageLanguage ?? 'de-DE'
			const languageCode: string = storedLanguage.split('-')[0].toLowerCase()
			return ISOLanguages.getName(code, languageCode)
		}
		return undefined
	}
}
