import { Component, ComponentRef, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import {
	ContactRead,
	ContactReadList,
	CorrespondenceTagRead,
	IMAPDirectories,
	MailIMAP,
	MailIMAPAccountRead,
	MailService,
	OrderRead,
	OrderReadListItem,
	OrderShort,
	OrdersService,
} from '@app/generated'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NotificationComponent } from '@core/components/notification/notification.component'
import { Pagination } from '@core/classes/pagination'
import { AppService } from '@app/general/services/app.service'
import { NotificationService } from '@core/services/notification.service'
import { Router } from '@angular/router'
import { ModalService } from '@core/services/modal.service'
import { MailboxInnerService } from '@app/mailbox/services/mailbox-inner.service'
import { LanguageService } from '@core/services/language.service'
import { MIMEType } from '@core/enums/mime-type.enum'
import { MailActionType } from '@app/mailbox/pages/new-email/new-email.page'
import { finalize, forkJoin, Observable, Subscription } from 'rxjs'
import { formatDate } from '@app/general/utils/format-date'
import { AssignOrderComponent } from '@app/mailbox/components/assign-order/assign-order.component'
import { MoveMailComponent } from '@app/mailbox/components/move-mail/move-mail.component'
import { ConfirmationComponent } from '@core/components/confirmation/confirmation.component'
import {
	base64ToUnicode,
	getCorrespondenceTagCategoryTranslation,
	printMail,
} from '@app/mailbox/functions/mailbox.utils'
import { TagMailComponent } from '@app/mailbox/components/tag-mail/tag-mail.component'
import { getItemTranslation } from '@app/general/utils/get-item-translation'
import { MailTab, MailTabInterface } from '@app/mailbox/components/mailbox-tabs/mailbox-tabs.component'
import { TableComponent } from '@core/components/table/table.component'
import { AclService } from '@app/acl/services/acl.service'
import { Permission } from '@app/acl/enums/permissions.enum'
import { LayoutService } from '@core/services/layout.service'
import { setLocalStorageScroll } from '@app/cost-invoices/pages/cost-invoices/cost-invoices.page'
import { ScrollItem } from '@app/orders/components/order-list/order-list.component'

export const MailFiltersLocalStorageKey = 'mail-view-list-filters'
export const MailListScrollStorageKey = 'mail-view-list-scroll'

interface TagItem {
	tag?: CorrespondenceTagRead
	dividerText?: string
}

type CustomFilterValue = 'true' | 'false' | null
type SearchValue = 'subject' | 'file' | 'body'

interface FilterList {
	text: string
	value: CustomFilterValue
}

interface SearchType {
	typeId: 'subject' | 'body' | 'file'
	label: string
	control: FormControl<boolean | null>
}

interface MailFiltersInterface {
	search: FormControl<string | null>
	tags: FormControl<(CorrespondenceTagRead | false)[] | null>
	orders: FormControl<(OrderRead | false)[] | null>
	dateFrom: FormControl<Date | null>
	dateTo: FormControl<Date | null>
	sender: FormControl<ContactRead[] | null>
	receiver: FormControl<ContactRead[] | null>
	seen: FormControl<'true' | 'false' | null>
	flagged: FormControl<'true' | 'false' | null>
	withAttachment: FormControl<'true' | 'false' | null>
}

export type MailFiltersValues = {
	[P in keyof MailFiltersInterface]: MailFiltersInterface[P]['value']
}
export type MailFiltersAndSearchValues = {
	filters: MailFiltersValues
	sortBy?: 'date' | 'from' | 'to' | 'subject'
	sortOrder?: 'asc' | 'desc'
	perPage?: number
	pageNumber?: number
}

@Component({
	selector: 'default-mail-page',
	templateUrl: './default-mail-page.component.html',
	styleUrl: './default-mail-page.component.scss',
})
export class DefaultMailPage {
	@Input({ required: true }) pageType!: MailTab
	@Input() mailboxTabs: MailTabInterface[] = [
		{ tabId: 'inbox', label: 'Inbox', icon: 'inbox' },
		{ tabId: 'drafts', label: 'Drafts', icon: 'edit' },
		{ tabId: 'outbox', label: 'Outbox', icon: 'send' },
		{ tabId: 'trash', label: 'Trash', icon: 'delete' },
		{ tabId: 'spam', label: 'Spam', icon: '' },
	]
	@Input() mailboxAdditionalTabs: MailTabInterface[] = [{ tabId: 'settings', label: 'Settings', icon: 'settings' }]
	@Input() isInsideModal = false
	@Input() selectedOrderId?: string

	@Output() tabChange: EventEmitter<MailTab> = new EventEmitter()

	@ViewChild('options') optionsElement: ElementRef<HTMLElement> | undefined

	models: MailIMAP[] = []
	selectedMail?: MailIMAP

	scrollY: number = 0

	filters: FormGroup<MailFiltersInterface> = new FormGroup({
		search: new FormControl<string | null>(null),
		tags: new FormControl<(CorrespondenceTagRead | false)[] | null>(null),
		orders: new FormControl<(OrderRead | false)[] | null>(null),
		dateFrom: new FormControl<Date | null>(null),
		dateTo: new FormControl<Date | null>(null),
		sender: new FormControl<ContactRead[] | null>(null),
		receiver: new FormControl<ContactRead[] | null>(null),
		seen: new FormControl<'true' | 'false' | null>(null),
		flagged: new FormControl<'true' | 'false' | null>(null),
		withAttachment: new FormControl<'true' | 'false' | null>(null),
	})

	filtersVisible = false

	searchTypes: SearchType[] = [
		{
			typeId: 'subject',
			label: $localize`Subject`,
			control: new FormControl(true),
		},
		{
			typeId: 'body',
			label: $localize`Content`,
			control: new FormControl(false),
		},
		// {
		// 	typeId: 'file',
		// 	label: $localize`Files`,
		// 	control: new FormControl(false),
		// },
	]

	selectedAccountControl: FormControl<MailIMAPAccountRead | null> = new FormControl<MailIMAPAccountRead | null>(
		null,
		Validators.required,
	)

	emailAccounts: MailIMAPAccountRead[] = []
	selectedCheckboxes: MailIMAP[] = []

	ordersList: OrderReadListItem[] = []
	tagsList: TagItem[] = []
	senderContactsList: ContactRead[] = []
	receiverContactsList: ContactRead[] = []

	senderSearchValue = ''
	isSenderSearchLoading = false
	senderSuggestion?: { email: string }
	receiverSearchValue = ''
	isReceiverSearchLoading = false
	receiverSuggestion?: { email: string }
	pageName = ''

	refreshPageInterval: any

	seenFilterList: FilterList[] = [
		{
			text: $localize`Seen`,
			value: 'true',
		},
		{
			text: $localize`Not seen`,
			value: 'false',
		},
	]
	flaggedFilterList: FilterList[] = [
		{
			text: $localize`Flagged`,
			value: 'true',
		},
		{
			text: $localize`Not flagged`,
			value: 'false',
		},
	]
	attachmentFilterList: FilterList[] = [
		{
			text: $localize`With attachments`,
			value: 'true',
		},
		{
			text: $localize`Without attachments`,
			value: 'false',
		},
	]

	emailPreviewStyle: 'fullscreen' | 'side' = 'fullscreen'

	doubleClickTimeout: any

	isLoaded: boolean = false

	private senderValueChanges?: any
	private receiverValueChanges?: any

	private sortKey?: 'date' | 'from' | 'to' | 'subject'
	private sortDir?: 'asc' | 'desc'
	private allTags: CorrespondenceTagRead[] = []

	protected readonly Object = Object
	private notificationComponents: { [key: string]: ComponentRef<NotificationComponent> } = {}

	protected pagination: Pagination = new Pagination()

	private filtersValue = this.filters?.value

	protected readonly text = {
		withAnAttachment: $localize`With an attachment`,
		hasAttachments: $localize`Download attachments`,
		markedWithAStar: $localize`Marked with a star`,
		unread: $localize`Unread`,
		read: $localize`Read`,
		markWithAStar: $localize`Mark with a star`,
		removeStar: $localize`Remove star`,
		refresh: $localize`Refresh`,
		reply: $localize`Reply`,
		forward: $localize`Forward`,
		filters: $localize`Filters`,
		move: $localize`Move`,
		delete: $localize`Delete`,
		tags: $localize`Tags`,
		selectEmailPlaceholder: $localize`Select Email`,
		searchPlaceholder: $localize`Search messages`,
		filterTags: $localize`Tags`,
		filtersOrders: $localize`Orders`,
		filtersSendMessageLabel: $localize`Send Date`,
		from: $localize`Form`,
		to: $localize`To`,
		sender: $localize`Sender`,
		receiver: $localize`Receiver`,
		clearFilters: $localize`Clear filters`,
		emailNoSubject: $localize`(No email subject)`,
		filterSeen: $localize`Seen`,
		filterFlagged: $localize`Flagged`,
		filterAttachment: $localize`Attachments`,
		assignOrder: $localize`Assign order`,
		notAssigned: $localize`Not Assigned`,
		analyzeAll: $localize`Analyze the unanalyzed emails`,
		forwardedMessage: $localize`Message forwarded`,
	}

	@ViewChild('tableComponent') tableComponent?: TableComponent
	@ViewChild('emailPreviewSide') emailPreviewSide?: ElementRef

	constructor(
		private loaderService: LoaderService,
		private appService: AppService,
		private mailService: MailService,
		private notificationService: NotificationService,
		private router: Router,
		private modalService: ModalService,
		private mailboxInnerService: MailboxInnerService,
		private languageService: LanguageService,
		private ordersService: OrdersService,
		private aclService: AclService,
		private layoutService: LayoutService,
	) {
		this.loaderService.disable()
		this.isLoaded = false
		this.mailboxInnerService.fetchCorrespondenceTags().subscribe({
			next: response => {
				this.allTags = response.items

				const groupedTags: { [key: string]: CorrespondenceTagRead[] } = {}

				for (const tag of response.items) {
					const category: string = this.getTagCategoryTranslation(tag)
					if (groupedTags[category]) {
						groupedTags[category].push(tag)
					} else {
						groupedTags[category] = [tag]
					}
				}
				for (const tag in groupedTags) {
					const tags = groupedTags[tag].map(t => ({ tag: t }))
					this.tagsList.push({ dividerText: tag })
					this.tagsList = this.tagsList.concat(tags)
				}
			},
			error: () => {
				console.error('Failed to fetch tags')
			},
		})
		this.mailboxInnerService.selectedEmailAccountChange$.subscribe(() => {
			this.selectedAccountControl.setValue(this.mailboxInnerService.selectedEmailAccount ?? null, { emitEvent: false })
			this.pagination.reloadList()
		})
		this.setRefreshPageInterval()
	}

	private setRefreshPageInterval(refreshInterval = 60_000): void {
		this.refreshPageInterval = setInterval(() => {
			if (!this.selectedMail && this.pagination.currentPage === 1 && !this.selectedCheckboxes) {
				this.loadMails($localize`The List has been refreshed`)
			}
		}, refreshInterval)
	}

	protected setPageHeader(): void {
		switch (this.pageType) {
			case 'inbox': {
				this.pageName = $localize`Inbox`
				break
			}
			case 'spam': {
				this.pageName = $localize`Spam`
				break
			}
			case 'outbox': {
				this.pageName = $localize`Outbox`
				break
			}
			case 'trash': {
				this.pageName = $localize`Trash`
				break
			}
		}
		if (!this.isInsideModal) {
			this.appService.title = this.pageName
			this.appService.actionButtonOptions = { text: $localize`New message`, iconName: 'add', action: this.addEmail }
		}
	}

	get getDirectoryType(): IMAPDirectories {
		switch (this.pageType) {
			case 'inbox':
				return IMAPDirectories.Inbox
			case 'outbox':
				return IMAPDirectories.Sent
			case 'trash':
				return IMAPDirectories.Trash
			case 'spam':
				return IMAPDirectories.Junk
			default:
				return IMAPDirectories.Inbox
		}
	}

	addEmail = (): void => {
		this.loaderService.setContain().enable(async () => {
			await this.router.navigateByUrl('/mailbox/new')
		})
	}

	subscription: Subscription | null = null

	ngOnInit(): void {
		const filters = this.getLocalStorageFilterAndSort
		this.filtersValue = JSON.parse(JSON.stringify(this.filters.value))

		if (filters?.filters) {
			this.filters.controls.search.setValue('')
			this.filters.patchValue(filters.filters)
			this.sortKey = filters.sortBy
			this.sortDir = filters.sortOrder
			this.pagination.setPerPage(filters.perPage ?? 25)
		}
		this.isLoaded = false
		this.setPageHeader()
		this.fetchEmailAddresses()

		if (!this.isInsideModal) {
			this.fetchOrders()
			const scroll = this.getLocalStorageScroll
			if (scroll?.scrollY) {
				const interval = setInterval(() => {
					if (this.isLoaded) {
						this.layoutService.layoutContainer?.nativeElement.scrollTo({
							top: scroll.scrollY ?? 0,
						})
						clearInterval(interval)
					}
				}, 300)
			}
			this.layoutService.afterScroll.subscribe(event => {
				const target = event!.target as HTMLElement

				if (target) {
					this.scrollY = target.scrollTop
				}
			})
		}

		this.mailboxInnerService.fetchSelectedAccount().subscribe(() => {
			this.selectedAccountControl.setValue(this.mailboxInnerService.selectedEmailAccount ?? null)
			this.subscription = this.pagination.listShouldBeReloaded().subscribe(() => {
				sessionStorage.removeItem(MailListScrollStorageKey)
				this.fetchData()
			})
		})
	}

	ngAfterViewInit(): void {
		this.loaderService.disable()
		if (!this.isInsideModal) {
			const scroll = this.getLocalStorageScroll
			if (scroll?.scrollY) {
				const interval = setInterval(() => {
					if (this.isLoaded) {
						this.layoutService.layoutContainer?.nativeElement.scrollTo({
							top: scroll.scrollY ?? 0,
						})
						clearInterval(interval)
					}
				}, 300)
			}
		}
	}

	ngOnDestroy(): void {
		this.isLoaded = false
		for (const key in this.notificationComponents) {
			if (this.notificationComponents[key] !== undefined) {
				this.notificationComponents[key]?.destroy()
			}
		}
		if (this.subscription) {
			this.subscription.unsubscribe()
		}
	}

	selectMail(mail: MailIMAP) {
		this.selectedMail = undefined

		const fullScreenAction = () => {
			clearTimeout(this.doubleClickTimeout)
			this.doubleClickTimeout = null
			this.emailPreviewStyle = 'fullscreen'

			this.loaderService.enable(() => {
				this.selectedMail = mail
				this.loaderService.disable()
				if (!this.isInsideModal) {
					this.appService.showButton({
						text: $localize`Message list`,
						headerIsHidden: true,
						action: this.closePreview,
					})
				}
			})
		}

		const sideAction = () => {
			this.doubleClickTimeout = setTimeout(() => {
				this.doubleClickTimeout = null
				this.emailPreviewStyle = 'side'

				this.loaderService.enable(() => {
					this.selectedMail = mail
					this.loaderService.disable()
				})
			}, 300)
		}
		if (this.isInsideModal) {
			fullScreenAction()
			return
		}
		if (this.doubleClickTimeout) {
			if (mail.id) {
				setLocalStorageScroll(mail.id, this.scrollY, MailListScrollStorageKey)
			}

			fullScreenAction()
		} else {
			sideAction()
		}
	}

	closePreview = (event: 'forceReload' | void | null): void => {
		this.selectedMail = undefined
		this.appService.dropButton()
		if (!this.isInsideModal) {
			const scroll = this.getLocalStorageScroll
			if (scroll?.scrollY) {
				const interval = setInterval(() => {
					if (this.isLoaded) {
						this.layoutService.layoutContainer?.nativeElement.scrollTo({
							top: scroll.scrollY ?? 0,
						})
						clearInterval(interval)
					}
				}, 300)
			}
		}
		if (event === 'forceReload') {
			this.pagination.reloadList()
			return
		}
		this.loaderService.disable()
	}

	queryChanged(value: any) {
		if (this.sortDir && !value.sort) {
			this.sortKey = undefined
			this.sortDir = undefined
			this.pagination.reloadList()
			return
		}
		if (value.sort.sortDir) {
			this.sortDir = value.sort.sortDir
		}
		if (value.sort.sortKey) {
			this.sortKey = value.sort.sortKey
			this.pagination.reloadList()
		}
	}

	checkboxesChanged(models: MailIMAP[]) {
		this.selectedCheckboxes = models
	}

	downloadAllAttachments(index: number) {
		const model = this.models[index]
		if (!model.uid || !this.mailboxInnerService.selectedEmailAccount?.id) {
			this.notificationService.error($localize`Application error`)
			return
		}
		this.mailService
			.getAttachmentAll(
				model.uid,
				this.mailboxInnerService.selectedEmailAccount.id,
				this.getDirectoryType,
				undefined,
				undefined,
				{ httpHeaderAccept: MIMEType.MIMEApplicationZip as 'application/json' },
			)
			.subscribe({
				next: response => {
					// todo err handling
					const blob = new Blob([response], { type: MIMEType.MIMEApplicationZip })
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = 'attachments.zip'
					document.body.appendChild(a)
					a.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(a)
				},
				error: () => {
					this.notificationService.error($localize`Failed to fetch mail attachments`)
				},
			})
	}

	forward(model: MailIMAP): void {
		this.loaderService.setContain().enable(async () => {
			await this.router.navigateByUrl(
				`/mailbox/${MailActionType.Forward}/${model.uid}?directory=${model.directory}&dbid=${model.id}`,
			)
		})
	}

	protected fetchData(): void {
		this.loadMails()
	}

	fetchEmailAddresses(search?: string): void {
		this.mailService.getImapAccounts(undefined, 0, 10, search, false, 'desc', false).subscribe({
			next: response => {
				this.emailAccounts = response.items
			},
			error: () => {
				this.notificationService.error($localize`Failed to fetch email addresses`)
			},
		})
	}

	onEmailAccountChange(account: MailIMAPAccountRead): void {
		this.mailboxInnerService.setSelectedAccount(account)
	}

	private loadMails(infoMessage?: string): void {
		this.setLocalStorageFiltersAndSort()
		this.loaderService.setContain().enable(() => {
			forkJoin({
				mails: this.mailService.getMails(
					this.getDirectoryType,
					this.mailboxInnerService.selectedEmailAccount!.id,
					this.pagination.offset(),
					this.pagination.perPage,
					this.getSelectedOrderParams(),
					this.filters.controls.tags.value?.length
						? this.filters.controls.tags.value.filter(t => !!t).map(t => (t as CorrespondenceTagRead).id)
						: undefined,
					this.filters.controls.search?.value || undefined,
					this.filters.controls.sender.value?.length ? this.filters.controls.sender.value.map(s => s.email) : undefined,
					this.filters.controls.receiver.value?.length
						? this.filters.controls.receiver.value.map(o => o.email)
						: undefined,
					this.filters.controls.dateFrom?.value ? formatDate(this.filters.controls.dateFrom.value) : undefined,
					this.filters.controls.dateTo?.value ? formatDate(this.filters.controls.dateTo.value) : undefined,
					this.getCustomFilterValue(this.filters.controls.seen.value),
					this.getCustomFilterValue(this.filters.controls.flagged.value),
					this.getCustomFilterValue(this.filters.controls.withAttachment.value),
					this.sortKey ?? undefined,
					this.sortDir ?? undefined,
					this.getSelectedSearchType,
					this.filters.controls.orders.value?.includes(false) ? false : undefined,
					this.filters.controls.tags.value?.includes(false) ? false : undefined,
				),
			})
				.pipe(finalize(this.loaderService.disable))
				.subscribe({
					next: response => {
						this.pagination.setTotal(response.mails.total)
						this.models = response.mails.items
						if (infoMessage) {
							this.notificationService.info(infoMessage)
						}
						if (!this.isLoaded) {
							const filters = this.getLocalStorageFilterAndSort
							if (filters) {
								this.pagination.setCurrentPage(filters.pageNumber ?? 1)
							}
							this.isLoaded = true
						}
						if (this.pageType === 'inbox') {
							this.setLocalStoragePageNumber()
						}

						this.uncheckCheckboxes()
					},
					error: () => {
						this.notificationService.error($localize`Failed to fetch mails`)
					},
				})
		})
	}

	getSelectedOrderParams(): string[] | undefined {
		if (this.selectedOrderId) {
			return [this.selectedOrderId]
		}

		return this.filters.controls.orders.value?.length
			? this.filters.controls.orders.value.filter(o => o !== false).map(o => (o as OrderRead).id)
			: undefined
	}

	getCustomFilterValue(value: CustomFilterValue): boolean | undefined {
		if (value === null) {
			return undefined
		}
		return value === 'true'
	}

	async addOrder(mail: MailIMAP): Promise<void> {
		if (!mail.id) {
			this.notificationService.warning($localize`Please try again later`)
			return
		}
		const modal = await this.modalService.open<AssignOrderComponent>(AssignOrderComponent)
		modal.title = $localize`Select orders`
		modal.iconName = 'link-alt'
		const orders = JSON.parse(JSON.stringify(mail.orders))
		if (orders.length) {
			modal.componentInstance.ordersControl.patchValue(orders)
		}
		const initialOrderIds = mail.orders.map(order => order.id)

		modal.afterClosed.subscribe((orders: (OrderRead | OrderShort)[] | undefined) => {
			if (orders) {
				const newOrderIds = orders.map(order => order.id)

				const ordersToAdd: string[] = []
				const ordersToUnassign: string[] = []

				for (const order of orders) {
					if (!initialOrderIds.includes(order.id)) {
						ordersToAdd.push(order.id)
					}
				}

				for (const orderId of initialOrderIds) {
					if (!newOrderIds.includes(orderId)) {
						ordersToUnassign.push(orderId)
					}
				}

				const addRequests = ordersToAdd.map(order => this.mailService.assignToOrder(mail.id!, { order_id: order }))

				const unassignRequests = ordersToUnassign.map(order =>
					this.mailService.unassignFromOrder(mail.id!, { order_id: order }),
				)

				forkJoin([...addRequests, ...unassignRequests]).subscribe({
					next: () => {
						mail.orders = orders.map(order => {
							if ('order_number' in order) {
								return order
							} else {
								return { order_number: order.number || '', id: order.id, container_number: order.container_number }
							}
						})
						this.notificationService.success($localize`Orders have been updated`)
					},
					error: () => {
						this.notificationService.error($localize`Was not able to update orders`)
					},
				})
			}
		})
	}

	async assOrderBulkActions(): Promise<void> {
		const mails = this.selectedCheckboxes
		if (!mails.length) {
			this.notificationService.warning($localize`No mails selected`)
			return
		}

		const modal = await this.modalService.open<AssignOrderComponent>(AssignOrderComponent)
		modal.title = $localize`Select orders for multiple mails`
		modal.iconName = 'link-alt'

		modal.afterClosed.subscribe((orders: (OrderRead | OrderShort)[] | undefined) => {
			if (orders?.length) {
				const newOrderIds = orders.map(order => order.id)
				const addRequests: Observable<any>[] = []

				mails.forEach(mail => {
					const existingOrderIds = mail.orders.map(order => order.id)

					const ordersToAdd = newOrderIds.filter(orderId => !existingOrderIds.includes(orderId))

					if (ordersToAdd.length) {
						addRequests.push(
							...ordersToAdd.map(orderId => this.mailService.assignToOrder(mail.id!, { order_id: orderId })),
						)

						mail.orders = [
							...mail.orders,
							...orders
								.filter(order => ordersToAdd.includes(order.id))
								.map(order => ({
									order_number: 'order_number' in order ? order.order_number : order.number || '',
									id: order.id,
									container_number: order.container_number ?? null,
								})),
						]
					}
				})

				if (addRequests.length) {
					forkJoin(addRequests).subscribe({
						next: () => {
							this.notificationService.success($localize`Orders have been updated for selected mails`)
						},
						error: () => {
							this.notificationService.error($localize`Failed to update orders for selected mails`)
						},
					})
				} else {
					this.notificationService.info($localize`No new orders to add for selected mails`)
				}
			}
		})
	}

	async moveMails(mail?: MailIMAP): Promise<void> {
		const mails = this.selectedCheckboxes

		if (!mails.length && !mail) {
			this.notificationService.warning($localize`Please select mail`)
			return
		}
		const modal = await this.modalService.open<MoveMailComponent>(MoveMailComponent)

		modal.title = $localize`Change directory`
		modal.iconName = 'link-alt'
		modal.componentInstance.disabledKeys = [this.getDirectoryType]
		const action = (selectedDirectory: IMAPDirectories) => {
			if (mail) {
				this.mailService
					.moveEmailToMailbox(
						mail.uid ?? '',
						mail.directory,
						selectedDirectory,
						this.mailboxInnerService.selectedEmailAccount!.id,
					)
					.subscribe({
						next: () => {
							this.loadMails()
							this.uncheckCheckboxes()
							this.notificationService.success($localize`Email has been moved`)
						},
						error: () => {
							this.notificationService.error($localize`Failed to move email`)
						},
					})
			} else if (mails.length) {
				this.mailService
					.moveMailsToMailbox(
						mails[0].directory,
						selectedDirectory,
						this.mailboxInnerService.selectedEmailAccount!.id,
						mails.map(m => m.uid ?? ''),
					)
					.subscribe({
						next: () => {
							this.loadMails()
							this.uncheckCheckboxes()
							this.notificationService.success($localize`Emails have been moved`)
						},
						error: () => {
							this.notificationService.error($localize`Failed to move email`)
						},
					})
			} else {
				this.notificationService.warning($localize`Please select at least one mail`)
			}
		}

		modal.afterClosed.subscribe((value: IMAPDirectories | undefined) => {
			if (!value) {
				return
			}
			action(value)
		})
	}

	async deleteMail(mail?: MailIMAP): Promise<void> {
		const mails = this.selectedCheckboxes
		const isTrash = this.getDirectoryType === 'Trash'
		if (!mails.length && !mail) {
			this.notificationService.warning($localize`Please select mail`)
			return
		}

		const action = () => {
			if (mail) {
				if (!isTrash) {
					this.mailService
						.moveEmailToMailbox(
							mail.uid ?? '',
							mail.directory,
							IMAPDirectories.Trash,
							this.mailboxInnerService.selectedEmailAccount!.id,
						)
						.subscribe({
							next: () => {
								this.loadMails()
								this.uncheckCheckboxes()
								this.notificationService.success($localize`Email has been moved to trash`)
							},
							error: () => {
								this.notificationService.error($localize`Failed to move email to trash`)
							},
						})
				} else {
					this.mailService
						.deleteMailByUid(mail.uid ?? '', this.mailboxInnerService.selectedEmailAccount!.id, mail.directory)
						.subscribe({
							next: () => {
								this.loadMails()
								this.notificationService.success($localize`Email has been deleted`)
							},
							error: () => {
								this.notificationService.error($localize`Failed to delete email`)
							},
						})
				}
			} else if (mails.length) {
				if (!isTrash) {
					this.mailService
						.moveMailsToMailbox(
							this.getDirectoryType,
							IMAPDirectories.Trash,
							this.mailboxInnerService.selectedEmailAccount!.id,
							mails.map(m => m.uid ?? ''),
						)
						.subscribe({
							next: () => {
								this.loadMails()
								this.uncheckCheckboxes()
								this.notificationService.success($localize`Emails has been moved to trash`)
							},
							error: () => {
								this.notificationService.error($localize`Failed to move emails to trash`)
							},
						})
				} else {
					this.mailService
						.deleteMailsByUids(
							this.mailboxInnerService.selectedEmailAccount!.id,
							this.getDirectoryType,
							mails.map(m => m.uid!).filter(id => !!id),
						)
						.subscribe({
							next: () => {
								this.loadMails()
								this.uncheckCheckboxes()
								this.notificationService.success($localize`Email has been deleted`)
							},
							error: () => {
								this.notificationService.error($localize`Failed to delete email`)
							},
						})
				}
			} else {
				this.notificationService.warning($localize`Please select at least one mail`)
			}
		}
		const modal = await this.modalService.open(ConfirmationComponent)
		modal.title = $localize`Danger`
		let message = ''
		if (mail || (!mail && mails.length === 1)) {
			message = isTrash
				? $localize`Are you sure to delete this mail?`
				: $localize`Are you sure to move to trash this mail?`
		} else {
			message = isTrash
				? $localize`Are you sure to delete selected mails?`
				: $localize`Are you sure to move to trash this mails?`
		}
		modal.componentInstance.message = message

		modal.afterClosed.subscribe(accepted => {
			if (accepted) {
				action()
			}
		})
	}

	private uncheckCheckboxes(): void {
		if (!this.isInsideModal) {
			this.tableComponent?.uncheckAllCheckboxes()
		}
	}

	starMessage(mail: MailIMAP): void {
		if (!mail.uid || !this.mailboxInnerService.selectedEmailAccount?.id) {
			return
		}
		this.loaderService.enable(() => {
			this.mailService
				.tagAsFlagged(mail.uid!, mail.directory, this.mailboxInnerService.selectedEmailAccount!.id, !mail.flagged)
				.pipe(
					finalize(() => {
						this.loaderService.disable()
					}),
				)
				.subscribe({
					next: () => {
						mail.flagged = !mail.flagged
						this.notificationService.success($localize`Mail has been flagged`)
					},
					error: () => {
						this.notificationService.error($localize`Failed to update mail`)
					},
				})
		})
	}

	refresh(): void {
		this.pagination.reloadList()
	}

	print(mail: MailIMAP): void {
		if (!mail.uid || !this.mailboxInnerService.selectedEmailAccount?.id) {
			return
		}

		this.mailService
			.getEmailBodyAndAttachments(mail.uid, this.mailboxInnerService.selectedEmailAccount.id, mail.directory, false)
			.subscribe({
				next: value => {
					printMail(mail, base64ToUnicode(value.body))
				},
				error: () => {
					this.notificationService.error($localize`Failed to fetch email details`)
				},
			})
	}

	/**
	 * Assigns tags to a given mail.
	 *
	 * @param {MailIMAP} mail - The mail object to assign tags to.
	 *
	 * @return {Promise<void>} A promise that resolves once the tags have been assigned successfully.
	 */
	async assignTag(mail: MailIMAP): Promise<void> {
		if (!mail.id) {
			return
		}
		const modal = await this.modalService.open<TagMailComponent>(TagMailComponent)
		modal.title = $localize`Select tags`
		modal.iconName = 'link-alt'
		modal.componentInstance.alreadyAssignedTags = mail.correspondence_tags
		modal.afterClosed.subscribe((tags: CorrespondenceTagRead[] | undefined) => {
			if (tags && mail.id) {
				const tagsToAdd: CorrespondenceTagRead[] = []
				const tagsToRemove: CorrespondenceTagRead[] = []

				const existingTagsIds = mail.correspondence_tags.map(t => t.id)
				const newTagsIds = tags.map(t => t.id)

				for (const tag of mail.correspondence_tags) {
					if (!newTagsIds.includes(tag.id)) {
						const fullTag = this.allTags.find(t => t.id === tag.id)
						if (fullTag) {
							tagsToRemove.push(fullTag)
						}
					}
				}

				for (const tag of tags) {
					if (!existingTagsIds.includes(tag.id)) {
						tagsToAdd.push(tag)
					}
				}

				const addRequests = tagsToAdd.map(tag =>
					this.mailService.assignTagToMail(mail.id!, { correspondence_tag_id: tag.id }),
				)

				const removeRequests = tagsToRemove.map(tag =>
					this.mailService.unassignTagToMail(mail.id!, { correspondence_tag_id: tag.id }),
				)

				this.loaderService.enable(() => {
					forkJoin([...addRequests, ...removeRequests])
						.pipe(finalize(() => this.loaderService.disable()))
						.subscribe({
							next: () => {
								this.loadMails()
							},
							error: () => {
								this.notificationService.error($localize`Failed to update tags`)
							},
						})
				})
			}
		})
	}

	/**
	 * Retrieves the corresponding tags of a given mail.
	 *
	 * @param {MailIMAP} mail - The mail object from which to retrieve the tags.
	 * @return {CorrespondenceTagRead[]} - An array of correspondence tags associated with the mail.
	 */
	getMailTags(mail: MailIMAP): CorrespondenceTagRead[] {
		return mail.correspondence_tags
			.map(tagId => this.allTags.find(tag => tag.id === tagId.id))
			.filter((tag): tag is CorrespondenceTagRead => tag !== undefined)
	}

	readMessageAction(mail: MailIMAP): void {
		if (!mail.uid || !this.mailboxInnerService.selectedEmailAccount?.id) {
			return
		}
		const seenValue = !mail.seen
		this.loaderService.enable()
		this.mailService
			.tagAsSeen(mail.uid, mail.directory, this.mailboxInnerService.selectedEmailAccount.id, seenValue)
			.pipe(finalize(() => this.loaderService.disable()))
			.subscribe({
				next: () => {
					mail.seen = seenValue
				},
				error: () => {
					this.notificationService.error($localize`Failed to mark mail as seen`)
				},
			})
	}

	isTagOptionSelected(tag: TagItem): boolean {
		if (tag.tag) {
			return !!this.filters.controls.tags.value?.includes(tag.tag)
		}
		return false
	}

	fetchOrders(search?: string): void {
		if (!this.aclCanOrderRead) {
			return
		}
		this.ordersService.getOrders(0, 10, search).subscribe({
			next: response => {
				this.ordersList = response.items
			},
			error: () => {
				console.error('Failed to fetch orders')
			},
		})
	}

	private getTagCategoryTranslation(tag: CorrespondenceTagRead): string {
		return getCorrespondenceTagCategoryTranslation(
			tag,
			this.languageService.getSelectedLanguage?.substring(0, 2) || 'en',
		)
	}

	async handleSenderValueChanges(value: string) {
		if (this.senderValueChanges) {
			clearTimeout(this.senderValueChanges)
		}

		this.senderValueChanges = setTimeout(() => {
			if (this.validateEmail(value)) {
				this.senderSuggestion = { email: value }
				return
			} else {
				this.senderSuggestion = undefined
			}

			this.isSenderSearchLoading = true
			this.mailService
				.getContacts(0, 10, value)
				.pipe(finalize(() => (this.isSenderSearchLoading = false)))
				.subscribe({
					next: (response: ContactReadList) => {
						this.senderContactsList = response.items
					},
					error: error => {
						console.error(error)
					},
				})
		}, 0)
	}

	handleFocusOutReceiver(): void {
		setTimeout(async () => {
			this.senderSuggestion = undefined
			this.senderSearchValue = ''
			this.senderContactsList = []
		}, 250)
	}

	async handleReceiverValueChanges(value: string) {
		if (this.receiverValueChanges) {
			clearTimeout(this.receiverValueChanges)
		}

		this.receiverValueChanges = setTimeout(() => {
			if (this.validateEmail(value)) {
				this.receiverSuggestion = { email: value }
				return
			} else {
				this.receiverSuggestion = undefined
			}

			this.isReceiverSearchLoading = true
			this.mailService
				.getContacts(0, 10, value)
				.pipe(finalize(() => (this.isReceiverSearchLoading = false)))
				.subscribe({
					next: (response: ContactReadList) => {
						this.receiverContactsList = response.items
					},
					error: error => {
						console.error(error)
					},
				})
		}, 0)
	}

	handleFocusOutSender(): void {
		setTimeout(async () => {
			this.senderSuggestion = undefined
			this.senderSearchValue = ''
			this.senderContactsList = []
		}, 250)
	}

	validateEmail(email: string): boolean {
		const emailValidator = Validators.email
		return email.length ? !emailValidator({ value: email } as any) : false
	}

	showFilters(): void {
		setTimeout(() => {
			this.filtersVisible = true
		})
	}

	closeFilters(): void {
		setTimeout(() => {
			this.filtersVisible = false
		})
	}

	resetFilters(): void {
		this.filters.patchValue({
			search: null,
			tags: null,
			orders: null,
			dateFrom: null,
			dateTo: null,
			withAttachment: null,
			sender: null,
			receiver: null,
			seen: null,
			flagged: null,
		})
		this.pagination.reset()
		this.pagination.reloadList()
	}

	filterClick(): void {
		this.filtersVisible = false
		this.pagination.reloadList()
	}

	onSearchEnter(): void {
		this.filterClick()
	}

	isOrderFilterSelected(order: OrderReadListItem): boolean {
		return !!this.filters.controls.orders.value?.filter(o => o !== false).find(o => (o as OrderRead).id === order.id)
	}

	onSearchTypeChange(type: SearchType): void {
		const subjectSelected = this.searchTypes.find(t => t.typeId === 'subject')?.control.value
		const contentSelected = this.searchTypes.find(t => t.typeId === 'body')?.control.value
		const fileSelected = this.searchTypes.find(t => t.typeId === 'file')?.control.value

		if (type.typeId === 'body') {
			if (!subjectSelected && contentSelected && !fileSelected) {
				return
			}
		} else if (type.typeId === 'subject') {
			if (subjectSelected && !contentSelected && !fileSelected) {
				return
			}
		} else if (type.typeId === 'file') {
			if (!subjectSelected && !contentSelected && fileSelected) {
				return
			}
		}

		type.control.patchValue(!type.control.value)
	}

	get getSelectedSearchType(): SearchValue[] {
		const subjectSelected = this.searchTypes.find(t => t.typeId === 'subject')?.control.value
		const contentSelected = this.searchTypes.find(t => t.typeId === 'body')?.control.value
		const fileSelected = this.searchTypes.find(t => t.typeId === 'file')?.control.value

		const result: SearchValue[] = []

		if (subjectSelected) {
			result.push('subject')
		}
		if (contentSelected) {
			result.push('body')
		}
		if (fileSelected) {
			result.push('file')
		}

		return result
	}

	get aclCanOrderRead(): boolean {
		return this.aclService.hasPermission(Permission.ORDERS_READ)
	}

	isMultiSelectOrderEnabled(): boolean {
		return !this.filters.controls.orders.value?.includes(false)
	}

	isMultiSelectTagsEnabled(): boolean {
		return !this.filters.controls.tags.value?.includes(false)
	}

	onOrderFilterChange(): void {
		setTimeout(() => {
			if (this.filters.controls.orders.value?.includes(false)) {
				this.filters.controls.orders.setValue([false])
			}
		})
	}

	onTagFilterChange(): void {
		setTimeout(() => {
			if (this.filters.controls.tags.value?.includes(false)) {
				this.filters.controls.tags.setValue([false])
			}
		})
	}

	canGoToTheOrder(id: string): boolean {
		const serializedUrl: string = this.router.serializeUrl(this.router.createUrlTree([`/orders/${id}`]))
		const currentUrl: string = this.router.url.split('?')[0]
		return serializedUrl != currentUrl
	}

	goToOrder(order: OrderShort): void {
		if (this.canGoToTheOrder(order.id)) {
			this.loaderService.enable(async () => {
				await this.router.navigateByUrl(`/orders/${order.id}`)
			})
		}
	}

	protected readonly getItemTranslation = getItemTranslation

	analyzeMail(model: MailIMAP) {
		if (model.uid && this.mailboxInnerService.selectedEmailAccount?.id && model.directory) {
			this.mailService
				.postAnalyseTargetEmail(model.uid, this.mailboxInnerService.selectedEmailAccount.id, model.directory)
				.subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully started analyze mail task`)
					},
					error: () => {
						this.notificationService.error($localize`Failed to start analyze mail task`)
					},
				})
		}
	}

	analyzeWithNoOrders() {
		if (this.mailboxInnerService.selectedEmailAccount?.id) {
			this.mailService
				.requestProcessingEmailsWithNoOrders([this.mailboxInnerService.selectedEmailAccount.id])
				.subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully analyzed emails without orders`)
					},
					error: () => {
						this.notificationService.error($localize`Failed to analyze emails without orders`)
					},
				})
		}
	}

	analyzeWithErrors() {
		if (this.mailboxInnerService.selectedEmailAccount?.id) {
			this.mailService.requestProcessingEmailsWithErrors([this.mailboxInnerService.selectedEmailAccount.id]).subscribe({
				next: () => {
					this.notificationService.success($localize`Successfully analyzed emails with errors`)
				},
				error: () => {
					this.notificationService.error($localize`Failed to analyze emails with errors`)
				},
			})
		}
	}

	get getLocalStorageFilterAndSort(): MailFiltersAndSearchValues | undefined {
		const storedFilters = sessionStorage.getItem(MailFiltersLocalStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}

	setLocalStorageFiltersAndSort(): void {
		const filters = this.getLocalStorageFilterAndSort
		sessionStorage.setItem(
			MailFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filters.value,
				sortOrder: this.sortDir,
				sortBy: this.sortKey,
				perPage: this.pagination.perPage,
				pageNumber: filters?.pageNumber ?? 1,
			}),
		)
	}

	setLocalStoragePageNumber(): void {
		sessionStorage.setItem(
			MailFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filters.value,
				sortOrder: this.sortDir,
				sortBy: this.sortKey,
				perPage: this.pagination.perPage,
				pageNumber: this.pagination.currentPage,
			}),
		)
	}

	aclIsAdmin() {
		const permissions = [Permission.ADMIN]
		return this.aclService.hasPermission(permissions)
	}

	get getLocalStorageScroll(): ScrollItem | undefined {
		const storedFilters = sessionStorage.getItem(MailListScrollStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}
}
