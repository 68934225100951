<epic-form-field [text]="textMoveLabel">
	<epic-select [control]="dictionaryControl">
		<epic-option
			*ngFor="let directory of directories"
			[value]="directory.key"
			>{{ directory.text }}
		</epic-option>
	</epic-select>
</epic-form-field>
<div class="submit-row">
	<epic-button
		[text]="textSave"
		(onClick)="submit()" />
</div>
