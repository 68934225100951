import { Component } from '@angular/core'
import { OrderRead, OrderReadListItem, OrderShort, OrdersService } from '@app/generated'
import { NotificationService } from '@core/services/notification.service'
import { ModalService } from '@core/services/modal.service'
import { FormControl } from '@angular/forms'
import { getItemTranslation } from '@app/general/utils/get-item-translation'

@Component({
	selector: 'app-assign-order',
	templateUrl: './assign-order.component.html',
	styleUrl: './assign-order.component.scss',
})
export class AssignOrderComponent {
	orders: OrderReadListItem[] = []
	ordersControl: FormControl<(OrderRead | OrderShort)[] | null> = new FormControl<(OrderRead | OrderShort)[] | null>(
		null,
	)
	protected readonly textLabel = $localize`Select orders`
	protected readonly textSave = $localize`Save`

	constructor(
		private ordersService: OrdersService,
		private notificationService: NotificationService,
		private modalService: ModalService,
	) {}

	ngOnInit() {
		this.fetchOrders()
	}

	fetchOrders(search?: string): void {
		this.ordersService.getOrders(0, 10, search, false, 'desc').subscribe({
			next: response => {
				this.orders = response.items
			},
			error: () => {
				this.notificationService.error($localize`Failed to fetch orders`)
				const instance = this.modalService.modalRef?.componentInstance
				if (instance && instance instanceof AssignOrderComponent) {
					this.modalService.close()
				}
			},
		})
	}

	submit() {
		const instance = this.modalService.modalRef?.componentInstance
		if (!instance || !(instance instanceof AssignOrderComponent)) {
			console.error('Create a handler for this use scenario')
			return
		}

		if (this.ordersControl.invalid) {
			this.notificationService.warning($localize`Please select order first`)
			return
		}
		this.modalService.modalRef.close(this.ordersControl.value ?? [])
	}

	get getSelectedValue(): string[] {
		if (!this.ordersControl.value) {
			return []
		}

		return this.ordersControl.value.map(o => {
			return 'order_number' in o ? o.order_number : o.number || ''
		})
	}

	isSelected(order: OrderReadListItem): boolean {
		return !!this.ordersControl.value?.find(o => o.id === order.id)
	}

	protected readonly getItemTranslation = getItemTranslation
}
