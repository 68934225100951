import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-attachment-icon',
	templateUrl: './attachment-icon.component.html',
	styleUrl: './attachment-icon.component.scss',
})
export class AttachmentIconComponent {
	@Input({ required: true }) mimeType!: string

	iconName(): string {
		const parts: string[] = this.mimeType.split('/')

		if (parts.length > 1) {
			const type: string = parts[1].toLowerCase()

			if (type == 'pdf') {
				return 'file-pdf'
			} else if (['png', 'jpg', 'jpeg', 'tif'].includes(type)) {
				return 'image'
			} else {
				return 'file'
			}
		} else {
			return 'file'
		}
	}
}
